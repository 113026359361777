export const type = 'notificationEvents';
export const endpoints = (builder) => ({
  getNotificationEvents: builder.query({
    query: () => ({
      url: `/notifications`,
      method: 'GET'
    }),
    providesTags: ({ data }) => {
      return data
        .map(({ eventId }) => ({ type, id: eventId }))
        .concat({ type, id: 'list' });
    }
  }),
  editNotificationEvents: builder.mutation({
    query: (params) => ({
      url: `/notifications`,
      method: 'PATCH',
      body: params
    }),
    invalidatesTags: ({eventId}) => [{ type, id: eventId }]
  })
});

export default endpoints;
