import {t} from 'ttag';
import React from 'react';
import {Box, Grid, Typography} from '@material-ui/core';
import EmployeeCard from '../../components/employee-card';
import EvaluationSpan from '../../components/evaluation-span';
import {convertDate} from '../../utils/helpers';
import DocumentView from './DocumentView';
import PropTypes from 'prop-types';

const FormsForm = ({ form }) => {
  const {
    instructor,
    trainee,
    occurredOn,
    evaluation,
    documents,
    description
  } = form;
  return (
    <Grid spacing={3} container>
      <Grid xs={12} md={6} item>
        <EmployeeCard label={t`Instructor`} employee={instructor} />
      </Grid>
      <Grid xs={12} md={6} item>
        <EmployeeCard label={t`Trainee`} employee={trainee} />
      </Grid>
      <Grid xs={12} md={4} item>
        <Typography variant='h6'>{t`Description`}</Typography>
        <Typography>{description || '-'}</Typography>
      </Grid>
      <Grid xs={12} md={4} item>
        <Typography variant='h6'>{t`Occurred On`}</Typography>
        <Typography>{occurredOn ? convertDate(occurredOn) : '-'}</Typography>
      </Grid>
      <Grid xs={12} md={4} item>
        <Typography variant='h6'>{t`Evaluation`}</Typography>
        {evaluation ? <EvaluationSpan evaluation={evaluation}/> : <Typography>-</Typography>}
      </Grid>
      <Grid xs={12} item>
        <Box display={'flex'} justifyContent={'space-around'}>
          {documents.map((d) => (
            <Box key={d.documentId} p={1}>
              <DocumentView form={form} document={d} />
            </Box>
          ))}
        </Box>
      </Grid>
    </Grid>
  );
};

FormsForm.propTypes = {
  instructor: PropTypes.object,
  trainee: PropTypes.object,
  occurredOn: PropTypes.string,
  evaluation: PropTypes.string,
  documents: PropTypes.array,
  description: PropTypes.string,
};

export default FormsForm;
