import {t} from 'ttag';
import React, {useState} from 'react';
import {CardHeader, Typography} from '@material-ui/core';
import {Page, SimpleCard} from 'sata-mui-react-components';
import {ArchivedFormsList} from './index';
import FormsDialog from '../forms/FormsDialog';


const ArchivedForms = () => {
  const [archivedFormsToOpen, setArchivedFormsToOpen] = useState(false);

  return (
    <Page>
      <SimpleCard>
        <CardHeader
          title={<Typography variant={'h2'}>{t`Archived Forms`}</Typography>}
        />

        <ArchivedFormsList
          openHandler={setArchivedFormsToOpen}
        />

        {archivedFormsToOpen && (
          <FormsDialog
            onClose={() => setArchivedFormsToOpen(false)}
            form={archivedFormsToOpen}
          />
        )}
      </SimpleCard>
    </Page>
  );
};

ArchivedForms.propTypes = {};

ArchivedForms.defaultProps = {};

export default ArchivedForms;
