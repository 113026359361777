import {t} from 'ttag';
import React, {useState} from 'react';
import {CardHeader, Typography} from '@material-ui/core';
import {Page, SimpleCard} from 'sata-mui-react-components';
import {RegisteredFormsList} from './index';
import FormsDialog from '../forms/FormsDialog';

const ValidatedForms = () => {
  const [validatedFormsToOpen, setValidatedFormsToOpen] = useState(false);

  return (
    <Page>
      <SimpleCard>
        <CardHeader
          title={<Typography variant={'h2'}>{t`Validated Forms`}</Typography>}
        />

        <RegisteredFormsList
          openHandler={setValidatedFormsToOpen}
        />

        {validatedFormsToOpen && (
          <FormsDialog
            onClose={() => setValidatedFormsToOpen(false)}
            form={validatedFormsToOpen}
            showArchiveBtn
            showSendToTraineeBtn
            showSendToInstructorBtn
          />
        )}
      </SimpleCard>
    </Page>
  );
};

ValidatedForms.propTypes = {};

ValidatedForms.defaultProps = {};

export default ValidatedForms;
