import React from 'react';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';

import './training-type-selector.scss';
import { useGetAllTrainingTypesQuery } from '../../store/apis/ops';

const TrainingTypeSelector = ({
  trainingType,
  onChange,
  label,
  placeholder,
  fullWidth = false,
  textFiledError,
  textFiledHelperText
}) => {
  const { data } = useGetAllTrainingTypesQuery();

  return (
    <div className={!fullWidth ? 'training-type-selector' : null}>
      <Autocomplete
        id='combo-box-demo'
        options={data ? data : []}
        value={trainingType}
        autoHighlight
        getOptionSelected={(option, value) => value.code === option.code}
        getOptionLabel={(option) => `(${option.code}) ${option.description}`}
        onChange={(e, o) => onChange(o)}
        style={{ width: '100%', fontSize: '80%' }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label ? label : null}
            placeholder={placeholder ? placeholder : null}
            variant='outlined'
            error={textFiledError}
            helperText={textFiledHelperText}
          />
        )}
        renderOption={(item) => (
          <div className={'training-type-lookup-display'}>
            <strong>({item.code}) </strong>
            <small>{item.description}</small>
          </div>
        )}
      />
    </div>
  );
};

TrainingTypeSelector.propTypes = {

};
TrainingTypeSelector.defaultProps = {
  textFiledError: false,
  textFiledHelperText: ''
};

export default TrainingTypeSelector;
