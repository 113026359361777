export const ROLES = {
  ADMIN: 'Admin',
  PNT: 'PNT',
  PNC: 'PNC',
  MANAGER: 'Manager'
};

export const hasSomeOf = (user, roles = []) =>
  !roles.length ||
  roles.some((rName) =>
    user?.roles.some(
      (uRole) => uRole.name.toLowerCase() === rName.toLowerCase()
    )
  );

export const hasEveryOf = (user, roles = []) =>
  !roles.length ||
  roles.every((rName) =>
    user?.roles.some(
      (uRole) => uRole.name.toLowerCase() === rName.toLowerCase()
    )
  );
