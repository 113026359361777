import {t} from 'ttag';
import React, {useState} from 'react';
import {CardHeader, Typography} from '@material-ui/core';
import {Button, Page, SimpleCard} from 'sata-mui-react-components';
import {UserDocumentsPendingList} from './index';
import {AddUserDocumentsPendingDialog, EditUserDocumentsPendingDialog} from './user-documents-pending-forms';
import {Add} from '@material-ui/icons';
import ViewUserDocumentsPendingDialog from './user-documents-pending-forms/ViewUserDocumentsPendingDialog';
import DeleteUserDocumentsDialog from './user-documents-pending-forms/DeleteUserDocumentsDialog';

const UserDocumentsPending = () => {
  const [userDocumentsPendingToEdit, setUserDocumentsPendingToEdit] = useState(false);
  const [userDocumentsPendingToView, setUserDocumentsPendingToView] = useState(false);
  const [userDocumentsPendingToDelete, setUserDocumentsPendingToDelete] = useState(false);
  const [documentsPendingToAdd, setDocumentsPendingToAdd] = useState(false);

  return (
    <Page>
      <SimpleCard>
        <CardHeader
          title={<Typography variant={'h2'}>{t`Documents`}</Typography>}
          action={
            <Button
              onClick={() => setDocumentsPendingToAdd(true)}
              startIcon={<Add />}
            >{t`ADD`}</Button>
          }
        />

        <UserDocumentsPendingList
          openHandler={setUserDocumentsPendingToView}
          editHandler={setUserDocumentsPendingToEdit}
          removeHandler={setUserDocumentsPendingToDelete}
        />

        {documentsPendingToAdd && (
          <AddUserDocumentsPendingDialog
            onClose={() => setDocumentsPendingToAdd(false)}
          />
        )}

        {userDocumentsPendingToView && (
          <ViewUserDocumentsPendingDialog
            onClose={() => setUserDocumentsPendingToView(false)}
            hash={userDocumentsPendingToView}
          />
        )}

        {userDocumentsPendingToEdit && (
          <EditUserDocumentsPendingDialog
            onClose={() => setUserDocumentsPendingToEdit(false)}
            hash={userDocumentsPendingToEdit}
          />
        )}

        {userDocumentsPendingToDelete && (
          <DeleteUserDocumentsDialog
            onClose={() => setUserDocumentsPendingToDelete(false)}
            document={userDocumentsPendingToDelete}
          />
        )}
      </SimpleCard>
    </Page>
  );
};

UserDocumentsPending.propTypes = {};

UserDocumentsPending.defaultProps = {};

export default UserDocumentsPending;
