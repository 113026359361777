import {t} from 'ttag';
import React, {useState} from 'react';
import {CardHeader, Typography} from '@material-ui/core';
import {Add} from '@material-ui/icons';
import {Button, SimpleCard} from 'sata-mui-react-components';

import NotificationEmailsList from './NotificationEmailsList';
import AddNotificationEmailsFormsDialog from './notification-emails-forms/AddNotificationEmailsFormsDialog';
import DeleteNotificationEmailsDialog from './notification-emails-forms/DeleteNotificationEmailsDialog';

const NotificationEmails = () => {
  const [notificationEmailToDelete, setNotificationEmailToDelete] = useState(false);
  const [notificationEmailsToImport, setNotificationEmailsToImport] = useState(false);

  return (
    <>
      <SimpleCard>
        <CardHeader
          title={<Typography variant={'h2'}>{t`Notification Emails`}</Typography>}
          action={
            <Button
              onClick={() => setNotificationEmailsToImport(true)}
              startIcon={<Add />}
            >{t`Add`}</Button>
          }
        />

        <NotificationEmailsList
          removeHandler={setNotificationEmailToDelete}
        />

        {notificationEmailsToImport && (
          <AddNotificationEmailsFormsDialog onClose={() => setNotificationEmailsToImport(false)} />
        )}

        {notificationEmailToDelete && (
          <DeleteNotificationEmailsDialog email={notificationEmailToDelete} onClose={() => setNotificationEmailToDelete(false)} />
        )}
      </SimpleCard>
    </>
  );
};

NotificationEmails.propTypes = {};

NotificationEmails.defaultProps = {};

export default NotificationEmails;
