import React from 'react';
import {
  Box,
  Button,
  Container,
  makeStyles,
  Typography,
  useMediaQuery
} from '@material-ui/core';
import { Page } from 'sata-mui-react-components';
import { t } from 'ttag';
import { useHistory } from 'react-router';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(3),
    paddingTop: 80,
    paddingBottom: 80
  },
  image: {
    maxWidth: '100%',
    width: 560,
    maxHeight: 300,
    height: 'auto'
  }
}));

const UnauthorizedView = () => {
  const classes = useStyles();
  const mobileDevice = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const history = useHistory();

  return (
    <Page className={classes.root}>
      <Container maxWidth='lg'>
        <Typography
          align='center'
          variant={mobileDevice ? 'h4' : 'h1'}
          color='textPrimary'
        >
          401: Unauthorized
        </Typography>
        <Typography align='center' variant='subtitle2' color='textSecondary'>
          You don't have permissions to access this page.
        </Typography>
        <Box mt={6} display='flex' justifyContent='center'>
          <img
            alt='Under development'
            className={classes.image}
            src={`${process.env.PUBLIC_URL}/static/images/undraw_close_tab_uk6g.svg`}
          />
        </Box>
        <Box mt={6} display='flex' justifyContent='center'>
          <Button
            color='primary'
            // component={RouterLink}
            // to="/"
            onClick={() => history.push('/')}
            variant='outlined'
          >
            {t`Home`}
          </Button>
        </Box>
      </Container>
    </Page>
  );
};

export default UnauthorizedView;
