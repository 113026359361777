import {type as unRegisteredFormsType} from './pendingFormsEndpoints';
import {type as registeredFormsType} from './registeredFormsEndpoints';
import {type as formsToValidateType} from './formsToValidateEndpoints';
import {type as validatedFormsType} from './validatedFormsEndpoints';
import {type as archivedFormsType} from './archivedFormsEndpoints';
export const type = 'forms';

export const formsEndpoints = (builder) => ({
  getForm: builder.query({
    query: ({ hash }) => ({
      url: `/forms/${hash}`,
      method: 'GET',
      destructFn: (d) => d?.data.data
    }),
    providesTags: ({ hash }) => [{ type, id: hash }]
  }),

  changeFormStatus: builder.mutation({
    query: ({ hash, status }) => ({
      url: `/forms/${hash}`,
      method: 'PATCH',
      body: {status}
    }),
    invalidatesTags: ({ hash }) => [
      { type, id: hash },
      { type: unRegisteredFormsType, id: 'list' },
      { type: formsToValidateType, id: 'list' },
      { type: registeredFormsType, id: 'list' },
      { type: validatedFormsType, id: 'list' },
      { type: archivedFormsType, id: 'list' }
    ]
  }),

  archiveForm: builder.mutation({
    query: (form) => ({
      url: `/forms/archive`,
      method: 'POST',
      body: form
    }),
    invalidatesTags: () => [
      { type, id: 'list' },
      { type: unRegisteredFormsType, id: 'list' },
      { type: registeredFormsType, id: 'list' },
      { type: formsToValidateType, id: 'list' },
      { type: validatedFormsType, id: 'list' },
      { type: archivedFormsType, id: 'list' }
    ]
  }),

  validateForm: builder.mutation({
    query: (form) => ({
      url: `/forms/validate`,
      method: 'POST',
      body: form
    }),
    invalidatesTags: () => [
      { type, id: 'list' },
      { type: unRegisteredFormsType, id: 'list' },
      { type: registeredFormsType, id: 'list' },
      { type: formsToValidateType, id: 'list' },
      { type: validatedFormsType, id: 'list' },
      { type: archivedFormsType, id: 'list' }
    ]
  }),

  registerForm: builder.mutation({
    query: (form) => ({
      url: `/forms/register`,
      method: 'POST',
      body: form
    }),
    invalidatesTags: () => [
      { type, id: 'list' },
      { type: unRegisteredFormsType, id: 'list' },
      { type: registeredFormsType, id: 'list' },
      { type: formsToValidateType, id: 'list' },
      { type: validatedFormsType, id: 'list' },
      { type: archivedFormsType, id: 'list' }
    ]
  }),

  registerPin: builder.mutation({
    query: (form) => ({
      url: `/datalink/update`,
      method: 'POST',
      body: form
    })
  })
});

export default formsEndpoints;
