import {Box, IconButton} from '@material-ui/core';
import {Edit} from '@material-ui/icons';
import {t} from 'ttag';
import React from 'react';

export const columns = ({ editHandler }) => [
  {
    field: 'name',
    headerName: t`Name`,
    sortable: false,
    disableColumnMenu: true,
    flex: 3
  },
  {
    field: 'email',
    headerName: t`Email`,
    sortable: false,
    disableColumnMenu: true,
    flex: 3
  },
  {
    field: 'actions',
    headerName: 'Actions',
    type: 'number',
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    renderCell: ({ row }) => {
      return (
        <Box>
          <IconButton
            aria-label='delete'
            color='primary'
            onClick={() => editHandler(row)}
            size='small'
          >
            <Edit fontSize='inherit' />
          </IconButton>
          {/*<IconButton*/}
          {/*  aria-label='delete'*/}
          {/*  onClick={() => removeHandler(row)}*/}
          {/*  size='small'*/}
          {/*>*/}
          {/*  <Delete fontSize='inherit' />*/}
          {/*</IconButton>*/}
        </Box>
      );
    }
  }
];

export const helper = {
  columns
};

export default helper;
