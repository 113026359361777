import { t } from 'ttag';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useDebouncedCallback } from 'use-debounce';

import RTKQueryFeedback from '../common/RTKQueryFeedback';
import { useGetUsersByPatternMutation } from '../../store/apis/ops';

const UserSearch = ({
  onSelectUser,
  label,
  classname,
  renderInputParams,
  autocompleteProps,
  textFiledError,
  textFiledHelperText
}) => {
  const [getUsers, { data, error, isLoading }] = useGetUsersByPatternMutation();

  const [inputValue, setInputValue] = useState('');

  const handleChange = (e, user) => {
    onSelectUser(user);
  };

  const searchHandler = useDebouncedCallback((pattern) => {
    getUsers(pattern);
  }, 1500);

  const handleInputChange = (e, value) => {
    setInputValue(value);
    if (value.length >= 3) {
      searchHandler(value);
    }
  };

  return (
    <div className={classname}>
      <RTKQueryFeedback error={error} />
      <Autocomplete
        noOptionsText={t`Enter an employee ID`}
        loadingText={t`Loading...`}
        loading={isLoading}
        options={data || []}
        inputValue={inputValue}
        freeSolo={false}
        getOptionLabel={(opt) => `${opt.employeeId}`}
        filterOptions={(opts, {inputValue= ''}) => opts.filter(o => `${o.employeeId} ${o.name}`.toLowerCase().includes(inputValue.toLowerCase()))}
        onInputChange={handleInputChange}
        onChange={handleChange}
        getOptionSelected={(option, value) =>
          value.employeeId === option.employeeId
        }

        renderOption={(item) => (
          <div className={'user-lookup-display'}>
            <strong>{item.name}</strong>
            &nbsp;
            <small>{item.employeeId}</small>
          </div>
        )}
        renderInput={(params) => (
          <TextField
            autoFocus
            placeholder={t`Start typing...`}
            {...params}
            variant='outlined'
            label={label}
            error={textFiledError}
            helperText={textFiledHelperText}
            {...renderInputParams}
          />
        )}
        {...autocompleteProps}
      />
    </div>
  );
};

UserSearch.propTypes = {
  onSelectUser: PropTypes.func.isRequired,
  value: PropTypes.shape({
    employeeId: PropTypes.string,
    name: PropTypes.string
  }),
  classname: PropTypes.string,
  autocompleteProps: PropTypes.object,
  label: PropTypes.string
};

UserSearch.defaultProps = {
  onSelectUser: (employeeID) => {},
  renderInputParams: {},
  classname: 'user-search',
  autocompleteProps: {},
  label: 'Employees',
  textFiledError: false,
  textFiledHelperText: ''
};

export default UserSearch;
