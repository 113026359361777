import {DateTime} from 'luxon';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {BasicTable} from 'sata-mui-react-components';
import {columns} from './pendingFormsListHelper';
import {useGetPendingFormsQuery} from '../../store/apis/ops';
import RTKQueryFeedback from '../../components/common/RTKQueryFeedback';
import DateRangePicker from '../../components/date-range-picker';
import {Grid, useMediaQuery} from '@material-ui/core';
import {t} from 'ttag';
import UserSearch from '../../components/user-search';
import TrainingTypeSelector from '../../components/training-type-selector';


const PendingFormsList = ({ openHandler }) => {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const [employee, setEmployee] = useState(null);
  const [trainingType, setTrainingType] = useState(null);
  const [filterRange, setFilterRange] = useState({
    startDate: DateTime.now().minus({ weeks: 2 }).toISODate(),
    endDate: DateTime.now().plus({ days: 2 }).toISODate()
  });
  const { data, error, isLoading, isFetching } = useGetPendingFormsQuery({
    page: 1,
    employeeId: employee?.employeeId,
    startDate: filterRange?.startDate,
    endDate: filterRange?.endDate,
    code: trainingType?.code
  });

  return (
    <>
      <RTKQueryFeedback error={error} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={4}>
          <UserSearch label={t`Employee`} value={{employee}} onSelectUser={(e) => setEmployee(e)}  />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <TrainingTypeSelector
            onChange={setTrainingType}
            trainingType={trainingType || null}
            label={t`Training Type`}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <DateRangePicker range={filterRange} onChange={setFilterRange} />
        </Grid>
      </Grid>
      <BasicTable
        getRowId={(row) => row.hash}
        loading={isLoading || isFetching}
        columns={columns({openHandler, isMobile })}
        rows={data?.data || []}
        onCellClick={({ row, field }) => {
          if (field !== 'actions') {
            openHandler(row);
          }
        }}
        disableExtendRowFullWidth={true}
        hideFooterPagination={true}
        autoPageSize
        autoHeight
      />
    </>
  );
};

PendingFormsList.propTypes = {
  setOpenEdit: PropTypes.func,
  setOpenRemove: PropTypes.func
};

PendingFormsList.defaultProps = {};

export default PendingFormsList;
