import {t} from 'ttag';
import React from 'react';
import {Box, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from '@material-ui/core';
import PropTypes from 'prop-types';
import {Button} from 'sata-mui-react-components';
import {useGetUserDocumentsByHashQuery, useRegisterUserDocumentsMutation} from '../../../store/apis/ops';
import RTKQueryFeedback from '../../../components/common/RTKQueryFeedback';
import ViewUserDocumentsPendingForm from './ViewUserDocumentsPendingForm';

const ViewUserDocumentsPendingDialog = ({hash, open, onClose }) => {
  const { data, error, isLoading, isSuccess } = useGetUserDocumentsByHashQuery({hash});
  const [registerUserDocuments, { data: registerDocData, error: registerDocError, isLoading: registerDocLoading}]= useRegisterUserDocumentsMutation({hash});

  if(!isSuccess){
    return <RTKQueryFeedback error={error} isLoading={isLoading} />
  }

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth='lg' fullWidth>
        <DialogTitle>
          <Typography variant='h2' component='div'>
            {t`Document: ${data.description}`}
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <ViewUserDocumentsPendingForm document={data}/>
        </DialogContent>
        <DialogActions>
          <Box display={'flex'} justifyContent={'space-between'} width={'100%'}>
              <Box p={1} px={0.4}>
                <Button variant='outlined' onClick={() => registerUserDocuments({documentId: hash}).then((r) => !r.error && onClose())}>
                  {t`Validate`}
                </Button>
              </Box>
              <Box p={1} px={0.4}>
                <Button variant='contained' onClick={onClose}>
                  {t`Cancel`}
                </Button>
              </Box>
            </Box>
        </DialogActions>
      </Dialog>
      <RTKQueryFeedback data={registerDocData} error={registerDocError} isLoading={registerDocLoading} />
    </>
  );
};

ViewUserDocumentsPendingDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func
};

ViewUserDocumentsPendingDialog.defaultProps = {
  open: true,
  onCLose: () => null,
};

export default ViewUserDocumentsPendingDialog;
