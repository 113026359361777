import { t } from 'ttag';
import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import DateFnsUtils from '@date-io/date-fns';
import { Box, Popover, Button } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';

import useCalendarShortcuts from './useCalendarShortcuts';

import './date-range-picker.scss';

/**
 * Range date picker with 2 calendars and shortcut ranges.
 *
 * @param {function} onChange
 * @param {object} range
 * @param bool disabled
 * @returns {JSX.Element}
 * @constructor
 */
const DateRangePicker = ({ onChange, range, disabled }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [startDate, setStartDate] = React.useState(range.startDate);
  const [endDate, setEndDate] = React.useState(range.endDate);
  const [first, setFirst] = React.useState(true);
  const [selected, setSelected] = React.useState(false);

  /**
   * Calculates the pivot date used to set the max date of left calendar
   * and min date of right calendar.
   *
   * @param range
   * @returns {Date}
   */
  const calculateFrontier = (range) => {
    return moment(range.startDate).clone().startOf('month').toDate();
  };

  const [frontier, setFrontier] = React.useState(calculateFrontier(range));
  const initialFrontier = calculateFrontier(range);

  /**
   * Calculates the selected date on right calendar. This moves the calendar to the
   * month of that date.
   *
   * @param {object} range
   * @param {Date} f
   * @param {Date|null} rightCal
   * @returns {null|Date|*}
   */
  const calculateRightCalDate = (range, f = frontier, rightCal = null) => {
    const pivot = range.endDate ? range.endDate : range.startDate;
    const today = moment(f).clone().endOf('month').add(1, 'day').toDate();
    const rightValue = rightCal ? rightCal : today;
    if (moment(pivot).isSameOrAfter(today, 'day')) {
      return pivot;
    }

    // This checks that right calendar is not on an month prior
    // to the pivot/frontier date (disabled part of calendar)
    if (moment(rightValue).isSameOrBefore(today)) {
      return today;
    }

    return null;
  };

  /**
   * Calculates the selected date on left calendar. This moves the calendar to the
   * month of that date.
   *
   * @param range
   * @param f
   * @returns {null|*}
   */
  const calculateLeftCalDate = (range, f = frontier) => {
    const today = moment(f).clone().endOf('month').toDate();
    if (moment(range.startDate).isSameOrBefore(today, 'day')) {
      return range.startDate;
    }

    return null;
  };

  const [rightCalDate, setRightCalDate] = React.useState(
    calculateRightCalDate(range)
  );
  const [leftCalDate, setLeftCalDate] = React.useState(
    calculateLeftCalDate(range)
  );

  /**
   * Handles input button click event, opening the popover
   *
   * @param event
   */
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  /**
   * Handles the popover close event.
   *
   * If the user does not click the "Select dates" button the internal value for
   * start date and end date is reset to the prop range values, as the user has
   * clicked outside of the popover, thus not selecting any dates.
   */
  const handleClose = () => {
    if (!selected) {
      setStartDate(range.startDate);
      setEndDate(range.endDate);
    }
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  /**
   * Resets the selected dates on calendars based on new star date and end date change.
   *
   * @param {Object} range
   * @param {Date} f
   * @param {Date|null} rightCalDate
   */
  const refreshCalendars = (range, f = frontier, rightCalDate = null) => {
    const rightDate = calculateRightCalDate(range, f, rightCalDate);
    if (rightDate) {
      setRightCalDate(rightDate);
    }

    const leftDate = calculateLeftCalDate(range, f);
    if (leftDate) {
      setLeftCalDate(leftDate);
    }

    if (moment(range.endDate).isSame(range.startDate, 'day')) {
      const focus = moment(range.endDate)
        .clone()
        .endOf('month')
        .add(1, 'day')
        .toDate();
      setRightCalDate(focus);
    }
  };

  /**
   * Handles calendar clicks.
   *
   * This sets the start date and end date in turns. It also triggers the update
   * of frontier date and calendars selected dates.
   * @param {Date} date
   */
  const handleDateSelected = (date) => {
    let _startDate = first ? date : startDate;
    let _endDate = date;

    if (!first && _startDate > _endDate) {
      const end = _endDate;
      _endDate = _startDate;
      _startDate = end;
    }

    const range = { startDate: _startDate, endDate: _endDate };
    setStartDate(_startDate);
    setEndDate(_endDate);

    const f = calculateFrontier(range);
    setFrontier(f);

    refreshCalendars(range, f, rightCalDate);
    setFirst(!first);
  };

  /**
   * Creates a JSX element that calendar used to render a day in the month grid.
   *
   * This element is set to wok with imported SCSS rules to display the start
   * and end dates as selected and all other days in between those two. Calendar
   * default selected day behavior is overridden.
   *
   * @param {Date} day
   * @param {Date} selectedDate calendar selected date
   * @param {boolean} dayInCurrentMonth if day date is on current month
   * @param {JSX.Element} dayComponent default rendered JSX element
   * @returns {JSX.Element|*}
   */
  const handleRenderDay = (
    day,
    selectedDate,
    dayInCurrentMonth,
    dayComponent
  ) => {
    if (!endDate) return dayComponent;

    const fixedEndDate = moment(endDate).set({
      hour: 12,
      minute: 0,
      second: 0,
      millisecond: 0
    });

    let className = 'selected-day';
    className += moment(day).isSame(startDate, 'day') ? ' start-day' : '';
    className += moment(day).isSame(fixedEndDate, 'day') ? ' end-day' : '';

    if (
      dayInCurrentMonth &&
      moment(day).isBetween(
        moment(startDate).subtract(1, 'day'),
        fixedEndDate.add(1, 'day'),
        'day'
      )
    ) {
      return <div className={className}>{dayComponent}</div>;
    }

    return <div className={'regular-day'}>{dayComponent}</div>;
  };

  /**
   * Handles clear dates button click.
   *
   * Sets start and end dates to the range prop value and resets the
   * frontier and calendar selected dates.
   */
  const handleClear = () => {
    setStartDate(range.startDate);
    setEndDate(range.endDate);

    const f = calculateFrontier(range);
    setFrontier(f);
    refreshCalendars(range, f, rightCalDate);
  };

  const shortcuts = useCalendarShortcuts();

  /**
   * Handles shortcut button click event.
   *
   * Sets the start and end dates to the shortcut values and resets
   * the frontier and calendar selected dates.
   *
   * @param startDate
   * @param endDate
   */
  const applyShortcut = ({ startDate, endDate }) => {
    setFirst(true);
    setStartDate(startDate);
    setEndDate(endDate);
    const range = { startDate, endDate };
    const f = calculateFrontier(range);
    setFrontier(f);
    refreshCalendars(range, f, rightCalDate);
  };

  /**
   * Handles the select dates button click and triggers the onChange event.
   */
  const handleSelected = () => {
    onChange({
      startDate: moment(startDate).format('YYYY-MM-DD'),
      endDate: moment(endDate).format('YYYY-MM-DD')
    });
    setSelected(true);
    setAnchorEl(null);
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div className={'date-range-picker'}>
        <Button
          className={'input-button'}
          disabled={disabled}
          color='secondary'
          variant='outlined'
          style={{display: 'flex', width: '100%'}}
          onClick={handleClick}
        >
          <Box display='flex'>
            <Box flex={1}>
              <FontAwesomeIcon icon={faCalendarAlt} />
            </Box>
            <Box flex={9}>{moment(startDate).format('MMMM Do YYYY')}</Box>
            <Box flex={1}>
              <FontAwesomeIcon icon={faArrowRight} />
            </Box>
            <Box flex={9}>{moment(endDate).format('MMMM Do YYYY')}</Box>
          </Box>
        </Button>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          PaperProps={{
            style: { width: '780px' }
          }}
        >
          <div className={'date-picker-wrapper'}>
            <div className={'date-picker-calendar-wrapper'}>
              <div className={'date-calendars-selector'}>
                <div className={'picker-wrapper start-calendar'}>
                  <DatePicker
                    autoOk
                    disableToolbar
                    value={leftCalDate}
                    initialFocusedDate={moment(initialFrontier)
                      .clone()
                      .endOf('month')
                      .toDate()}
                    onChange={handleDateSelected}
                    orientation='landscape'
                    variant={'static'}
                    openTo='date'
                    renderDay={handleRenderDay}
                    maxDate={moment(frontier).clone().endOf('month').toDate()}
                  />
                </div>
                <div className={'picker-wrapper end-calendar'}>
                  <DatePicker
                    disableToolbar
                    value={rightCalDate}
                    initialFocusedDate={moment(initialFrontier)
                      .clone()
                      .endOf('month')
                      .add(1, 'day')
                      .toDate()}
                    onChange={handleDateSelected}
                    orientation='landscape'
                    variant={'static'}
                    openTo='date'
                    renderDay={handleRenderDay}
                    minDate={moment(frontier)
                      .clone()
                      .endOf('month')
                      .add(1, 'day')
                      .toDate()}
                  />
                </div>
              </div>
              <div className={'date-range-picker-footer'}>
                <Button
                  variant='outlined'
                  color='primary'
                  onClick={handleClear}
                >{t`Reset dates`}</Button>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={handleSelected}
                >{t`Select dates`}</Button>
              </div>
            </div>
            <div className={'selection-shortcuts'}>
              {shortcuts.map((s, i) => (
                <Button
                  key={i}
                  onClick={() => {
                    applyShortcut(s);
                  }}
                  variant='outlined'
                >
                  {s.label}
                </Button>
              ))}
            </div>
          </div>
        </Popover>
      </div>
    </MuiPickersUtilsProvider>
  );
};

DateRangePicker.propTypes = {
  onChange: PropTypes.func,
  range: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string
  }),
  disabled: PropTypes.bool
};

DateRangePicker.defaultProps = {
  onChange: () => {},
  range: {
    startDate: moment().subtract(1, 'day').toDate(),
    endDate: moment().toDate()
  },
  disabled: false
};

export default DateRangePicker;
