import {t} from 'ttag';
import React, {useEffect} from 'react';
import {Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography} from '@material-ui/core';
import PropTypes from 'prop-types';
import {Button} from 'sata-mui-react-components';
import FormsForm from './FormsForm';
import {
  useArchiveFormMutation,
  useChangeFormStatusMutation,
  useGetFormQuery,
  useValidateFormMutation
} from '../../store/apis/ops';
import RTKQueryFeedback from '../../components/common/RTKQueryFeedback';
import {FORM_STATUSES} from "../../utils/helpers";


const FormsDialog = ({
  form: {hash},
  open,
  onClose,
  showSendToInstructorBtn,
  showSendToTraineeBtn,
  showArchiveBtn,
  showValidateBtn
}) => {

  const {data: form, ...getFormResponse} = useGetFormQuery({hash});
  const [archiveForm, archiveFormData] = useArchiveFormMutation();
  const [validateForm, validateFormData] = useValidateFormMutation();
  const [changeStatus, changeStatusData] = useChangeFormStatusMutation();

  useEffect(() => {
    if(archiveFormData.data || changeStatusData.data || validateFormData.data) {
      onClose();
    }
  }, [archiveFormData.data, changeStatusData.data, validateFormData.data, onClose]);

  const handleArchiveForm = () => {
    archiveForm(form);
  }

  const handleValidateForm = () => {
    validateForm(form);
  }

  const handleChangeFormStatus = (status) => {
    changeStatus({...form, status});
  }

  if (!form) {
    return <RTKQueryFeedback error={getFormResponse.error} isLoading={getFormResponse.isLoading} />
  }

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth='lg'>
        <DialogTitle>
          <Typography variant='h2' component='div'>
            {t`${form?.trainee.name} Form: ${form?.type?.code}`}
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <FormsForm form={form} />
        </DialogContent>
        <DialogActions>
          <Grid container>
            <Grid item>
              {showSendToInstructorBtn && (
                <Box p={1} px={0.4}>
                  <Button
                    variant='outlined'
                    onClick={() => handleChangeFormStatus(FORM_STATUSES.PENDING)}
                  >
                    {t`Send to Instructor`}
                  </Button>
                </Box>
              )}
            </Grid>
            <Grid item>
              {showSendToTraineeBtn && (
                <Box p={1} px={0.4}>
                  <Button
                    variant='outlined'
                    onClick={() => handleChangeFormStatus(FORM_STATUSES.REGISTERED)}
                  >
                    {t`Send to Trainee`}
                  </Button>
                </Box>
              )}
            </Grid>
            <Grid item>
              {showArchiveBtn && (
                <Box p={1} px={0.4}>
                  <Button variant='contained' onClick={handleArchiveForm}>
                    {t`Archive`}
                  </Button>
                </Box>
              )}
            </Grid>
            <Grid item>
              {showValidateBtn && (
                <Box p={1} px={0.4}>
                  <Button variant='outlined' onClick={handleValidateForm}>
                    {t`Validate`}
                  </Button>
                </Box>
              )}
            </Grid>
          </Grid>
          <Grid container style={{justifyContent: 'end'}}>
            <Grid item >
              <Box p={1} px={0.8}>
                <Button variant='contained' onClick={onClose}>
                  {t`Close`}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      <RTKQueryFeedback error={archiveFormData.error} isLoading={archiveFormData.isLoading} data={archiveFormData.data} />
      <RTKQueryFeedback error={validateFormData.error} isLoading={validateFormData.isLoading} data={validateFormData.data} />
      <RTKQueryFeedback error={changeStatusData.error} isLoading={changeStatusData.isLoading} data={changeStatusData.data} />
    </>
  );
};

FormsDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func
};

FormsDialog.defaultProps = {
  open: true
};

export default FormsDialog;
