import {t} from 'ttag';
import {useFormik} from 'formik';
import PropTypes from 'prop-types';
import {Button} from 'sata-mui-react-components';
import {Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField} from '@material-ui/core';
import RTKQueryFeedback from '../../components/common/RTKQueryFeedback';
import {useRegisterPinMutation} from '../../store/apis/ops';
import {registerPinValidationSchema} from './helpers';
import {Alert} from '@material-ui/lab';

export const PinFormDialog = ({ open, onClose }) => {
  const [registerPin, registerPinData] = useRegisterPinMutation();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {},
    validationSchema: registerPinValidationSchema,
    onSubmit: (values) => {
      registerPin({
        ...values
      }).then((r) => !r.error && onClose());
    }
  });

  return (
    <Dialog open={open} onClose={onClose} maxWidth={'sm'} fullWidth>
      <DialogTitle>{t`Define Pin`}</DialogTitle>
      <DialogContent>
        <Grid spacing={1} container>
          <Grid xs={12} item>
            <Alert severity="info">{t`Define um ‘PIN’ de 8 caracteres. Em que os primeiros 4 caracteres, devem corresponder aos últimos 4 caracteres do número de utilizador.`}</Alert>
          </Grid>
          <Grid xs={12} item>
            <TextField
              name={'pin'}
              label={'Pin'}
              variant='outlined'
              value={formik.values?.pin}
              onChange={(e) => formik.setFieldValue('pin', e.target.value)}
              error={Boolean(formik.errors.pin)}
              helperText={formik.errors.pin}
              type={'password'}
              fullWidth
              required
              focused
            />
          </Grid>
          <Grid xs={12} item>
            <TextField
              name={'pinConfirmation'}
              type={'password'}
              label='Repeat Pin'
              variant='outlined'
              value={formik.values?.pinConfirmation}
              onChange={(e) => formik.setFieldValue('pinConfirmation', e.target.value)}
              error={Boolean(formik.errors.pinConfirmation)}
              helperText={formik.errors.pinConfirmation}
              fullWidth
              required
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Box p={1} px={0.4}>
          <Button variant='outlined' onClick={onClose}>
            {t`Cancel`}
          </Button>
        </Box>
        <Box p={1} px={0.4}>
          <Button variant='contained' onClick={() => formik.handleSubmit()}>
            {t`Save`}
          </Button>
        </Box>
      </DialogActions>
      <RTKQueryFeedback error={registerPinData.error} isLoading={registerPinData.isFetching} />
    </Dialog>
  );
};

PinFormDialog.propTypes = {
  open: PropTypes.bool
};

PinFormDialog.defaultProps = {
  open: true
};

export default PinFormDialog;
