import {t} from 'ttag';
import React from 'react';
import PropTypes from 'prop-types';
import {Grid, TextField} from '@material-ui/core';
import Attachments from '../../../components/attachments';
import {DateTime} from 'luxon';

const EditUserDocumentsPendingForm = ({ formik }) => {
  return (
    <Grid spacing={3} container>
      <Grid xs={12} md={6} item>
        <TextField
          label={t`Issued Date`}
          name={'date'}
          type='date'
          color='primary'
          variant='outlined'
          onChange={(e) => formik.setFieldValue('date', e.target.value)}
          value={formik.values?.date ? DateTime.fromISO(formik.values?.date).toFormat('yyyy-MM-dd') : ''}
          error={Boolean(formik.errors.date)}
          helperText={formik.errors.date || ''}
          fullWidth
          required
        />
      </Grid>
      <Grid xs={12} md={6} item>
        <TextField
          label={t`Expiry Date`}
          name={'validity'}
          type='date'
          color='primary'
          variant='outlined'
          onChange={(e) => formik.setFieldValue('validity', e.target.value)}
          value={formik.values?.validity ? DateTime.fromISO(formik.values?.validity).toFormat('yyyy-MM-dd') :  ''}
          error={Boolean(formik.errors.validity)}
          helperText={formik.errors.validity || ''}
          fullWidth
          required
        />
      </Grid>
      <Grid xs={12} item>
        <Attachments
          onChange={([attachment]) => formik.setFieldValue('base64', `data:${attachment.type};base64,${attachment.content}`)}
          allowedFileTypes={'.pdf'}
          multiple={false}
          hideSizeFeedback
          hideResetBtn
        />
      </Grid>
    </Grid>
  );
};

EditUserDocumentsPendingForm.propTypes = {
  formik: PropTypes.object
};

EditUserDocumentsPendingForm.defaultProps = {
  formik: {}
};

export default EditUserDocumentsPendingForm;
