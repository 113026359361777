import React from "react";
import {create} from "jss";
import {BrowserRouter as Router} from "react-router-dom";
import {
  jssPreset,
  StylesProvider,
  ThemeProvider
} from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

import routes from "./routes";
import theme from "./theme/theme";
import {StoreProvider, SnackbarProvider, renderRoutes} from "./utils";

const jss = create({plugins: [...jssPreset().plugins]});

const App = () => {
  return (
    <StoreProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline/>
        <StylesProvider jss={jss}>
          <SnackbarProvider>
            <Router>
              {renderRoutes(routes)}
            </Router>
          </SnackbarProvider>
        </StylesProvider>
      </ThemeProvider>
    </StoreProvider>
  );
};

export default App;
