import {t} from 'ttag';
import React from 'react';
import PropTypes from 'prop-types';
import {Grid, TextField} from '@material-ui/core';

import RolesSearch from '../../../../components/role-search';

const EmployeeForm = ({ formik }) => {
  return (
    <Grid spacing={3} container>
      <Grid xs={12} md={6} item>
        <TextField
          disabled
          label={t`Name`}
          name={'name'}
          color='primary'
          variant='outlined'
          onChange={(e) => formik.setFieldValue('name', e.target.value)}
          value={formik.values?.name || ''}
          error={Boolean(formik.errors.name)}
          helperText={formik.errors.name || ''}
          fullWidth
          required
        />
      </Grid>
      <Grid xs={12} md={6} item>
        <TextField
          disabled
          label={t`Email`}
          name={'email'}
          color='primary'
          variant='outlined'
          onChange={(e) => formik.setFieldValue('email', e.target.value)}
          value={formik.values?.email || ''}
          error={Boolean(formik.errors.email)}
          helperText={formik.errors.email || ''}
          fullWidth
          required
        />
      </Grid>
      <Grid xs={12} item>
        <RolesSearch
          value={formik.values?.roles}
          onChange={(roles) => formik.setFieldValue('roles', roles)}
        />
      </Grid>
    </Grid>
  );
};

EmployeeForm.propTypes = {
  formik: PropTypes.object
};

EmployeeForm.defaultProps = {
  formik: {}
};

export default EmployeeForm;
