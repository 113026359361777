import { t } from 'ttag';
import * as Yup from 'yup';

export const registerPinValidationSchema = Yup.object().shape({
  pin: Yup.string()
    .required(t`No password provided.`)
    .min(8, t`Password is too short - should have 8 chars.`)
    .max(8, t`Password is too long - should have 8 chars.`),
  pinConfirmation: Yup.string()
    .required(t`No password provided.`)
    .oneOf([Yup.ref('pin')], t`Passwords must match`)
});
