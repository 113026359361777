import * as Yup from 'yup';
import { t } from 'ttag';

export const userDocumentsPendingValidationSchema = Yup.object().shape({
  date: Yup.string().required(t`Required field.`),
  validity: Yup.string().required(t`Required field.`),
  type: Yup.object().required(t`Required field.`),
  user: Yup.object().required(t`Required field.`),
  number: Yup.string().required(t`Required field.`)
});

export const editUserDocumentsPendingValidationSchema = Yup.object().shape({
  date: Yup.string().required(t`Required field.`),
  validity: Yup.string().required(t`Required field.`),
});

