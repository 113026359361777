import React, { useState } from 'react';
import { BasicTable } from 'sata-mui-react-components';
import { useGetRolesQuery } from '../../../store/apis/ops';
import RTKQueryFeedback from '../../../components/common/RTKQueryFeedback';
import { columns } from './rolesListHelper';

const RolesList = ({ editHandler, deleteHandler }) => {
  const [page, setPage] = useState(1);
  const { data = [], error, isLoading } = useGetRolesQuery(page);
  return (
    <>
      <RTKQueryFeedback error={error} />
      <BasicTable
        pagination
        page={(page - 1)}
        rowCount={data?.count || 0}
        onPageChange={(newPage) => setPage(newPage + 1)}
        paginationMode="server"
        getRowId={(row) => row.roleId}
        loading={isLoading}
        columns={columns({ editHandler, removeHandler: deleteHandler })}
        rows={data?.data || []}
        autoHeight
        autoPageSize
        pageSize={data?.pagination?.rowsPerPage || 5}
        disableExtendRowFullWidth={true}
      />
    </>
  );
};

RolesList.propTypes = {};

RolesList.defaultProps = {};

export default RolesList;
