import React from 'react';
import PropTypes from 'prop-types';
import {Document, Page} from 'react-pdf/dist/esm/entry.webpack';
import {useGetDocumentPdfQuery, useUpdateDocumentMutation} from '../../store/apis/ops';
import Attachments from '../../components/attachments';
import {openBase64Window} from '../../utils/helpers';
import './documentView.css';
import RTKQueryFeedback from '../../components/common/RTKQueryFeedback';

const DocumentView = ({form, document, disabled}) => {
  const {data, error, isLoading} = useGetDocumentPdfQuery(document);
  const [updateDocument, updateDocumentResponse] = useUpdateDocumentMutation();

  return (
    <>
      <Attachments
        onChange={([attachment]) => {
          updateDocument({form, document: {...document, base64: `data:${attachment.type};base64,${attachment.content}`}})
        }}
        dropAttachmentsLabel={document.type}
        allowedFileTypes={'.pdf'}
        loading={isLoading}
        multiple={false}
        disabled={disabled}
        hideSizeFeedback
        hideFileElements
        hideResetBtn
      >
        <Document error={error} loading={isLoading && 'Loading...'} file={data}>
          <Page pageNumber={1} onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            openBase64Window(data)
          }}/>
        </Document>
      </Attachments>
      <RTKQueryFeedback
        data={updateDocumentResponse.data}
        error={updateDocumentResponse.error}
        isLoading={updateDocumentResponse.isLoading}
      />
    </>
  );
};

export const DocumentShape = PropTypes.shape({
  documentId: PropTypes.string.isRequired,
  type: PropTypes.string,
  name: PropTypes.string,
  mediaType: PropTypes.string,
  metadata: PropTypes.shape({
    file: PropTypes.string,
    thumbnail: PropTypes.string,
  }),
});

DocumentView.propTypes = {
  form: PropTypes.shape({
    hash: PropTypes.string,
  }),
  document: DocumentShape,
  disabled: PropTypes.bool,
};

DocumentView.defaultProps = {
  document: null,
  disabled: false
};

export default DocumentView;
