import {t} from 'ttag';
import React, {useState} from 'react';
import {CardHeader, Typography} from '@material-ui/core';
import {Page, SimpleCard} from 'sata-mui-react-components';
import {FormsToValidateList} from './index';
import FormsDialog from '../forms/FormsDialog';

const FormsToValidate = () => {
  const [formsToValidate, setFormsToValidate] = useState(false);

  return (
    <Page>
      <SimpleCard>
        <CardHeader
          title={<Typography variant={'h2'}>{t`Forms to validate`}</Typography>}
        />

        <FormsToValidateList
          openHandler={setFormsToValidate}
        />

        {formsToValidate && (
          <FormsDialog
            onClose={() => setFormsToValidate(false)}
            form={formsToValidate}
            showValidateBtn
          />
        )}
      </SimpleCard>
    </Page>
  );
};

export default FormsToValidate;
