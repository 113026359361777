import {t} from 'ttag';
import React from 'react';
import PropTypes from 'prop-types';
import {Grid, TextField} from '@material-ui/core';
import NotificationEmailsSearch from '../../../../components/notification-emails-search';


const NotificationEventsForm = ({ formik }) => {

  return (
    <Grid spacing={3} container>
      <Grid xs={12} item>
        <TextField
          disabled
          label={t`Name`}
          name={'name'}
          color='primary'
          variant='outlined'
          onChange={(e) => formik.setFieldValue('name', e.target.value)}
          value={formik.values?.name || ''}
          error={Boolean(formik.errors.name)}
          helperText={formik.errors.name || ''}
          fullWidth
        />
      </Grid>
      <Grid xs={12} item>
        <NotificationEmailsSearch value={formik.values?.emails || []} onChange={e => formik.setFieldValue('emails',e)}/>
      </Grid>
    </Grid>
  );
};

NotificationEventsForm.propTypes = {
  formik: PropTypes.object
};

NotificationEventsForm.defaultProps = {
  formik: {}
};

export default NotificationEventsForm;
