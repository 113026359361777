import { t } from 'ttag';
import React from 'react';
import {
  Box,
  DialogContent,
  Dialog,
  DialogTitle,
  Typography,
  DialogActions
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { Button } from 'sata-mui-react-components';

import PendingFormsForm from '../PendingFormsForm';
import { pendingFormsValidationSchema } from '../helpers';
import { useAddPendingFormMutation } from '../../../../store/apis/ops';
import RTKQueryFeedback from '../../../../components/common/RTKQueryFeedback';

const AddPendingFormsDialog = ({ open, onClose }) => {
  const [addForm, { data, error, isLoading }] = useAddPendingFormMutation();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {},
    validationSchema: pendingFormsValidationSchema,
    onSubmit: (values) => {
      addForm({
        instructorId: values.instructor.employeeId,
        traineeId: values.trainee.employeeId,
        code: values.type.code,
        description: values.type.description,
        expiry: values.date,
        occurredOn: values.occurredOn,
        validity: values.validity,
        group: values.group
      }).then((r) => !r.error && onClose());
    }
  });

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>
          <Typography variant='h2' component='div'>
            {t`Create new form`}
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <PendingFormsForm formik={formik} />
        </DialogContent>
        <DialogActions>
          <Box p={1} px={0.4}>
            <Button variant='outlined' onClick={onClose}>
              {t`Cancel`}
            </Button>
          </Box>
          <Box p={1} px={0.4}>
            <Button variant='contained' onClick={() => formik.handleSubmit()}>
              {t`Save`}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
      <RTKQueryFeedback error={error} isLoading={isLoading} data={data} />
    </>
  );
};

AddPendingFormsDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func
};

AddPendingFormsDialog.defaultProps = {
  open: true
};

export default AddPendingFormsDialog;
