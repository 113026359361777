import React from 'react';
import RTKQueryFeedback from '../../../../components/common/RTKQueryFeedback';
import { t } from 'ttag';
import { AlertDialogModal, Button } from 'sata-mui-react-components';
import { useDeleteRoleMutation } from '../../../../store/apis/ops';
import {
  Box,
  DialogActions,
  DialogContent,
  Grid,
  Typography
} from '@material-ui/core';
import PropTypes from 'prop-types';

const DeleteRoleDialog = ({ role, open, onClose }) => {
  const [deleteRole, { data, error, isLoading, isSuccess }] =
    useDeleteRoleMutation();

  return (
    <>
      <RTKQueryFeedback
        data={data}
        error={error}
        isLoading={isLoading}
        isSuccess={isSuccess}
        successMessage={t`The item was successfully deleted!`}
      />
      <AlertDialogModal
        variant='error'
        title={t`Role`}
        open={open}
        onClose={onClose}
      >
        <DialogContent dividers>
          <Grid spacing={3} container>
            <Grid xs={12} item>
              <Typography>
                {t`Do you really want to remove this item?`}
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Box mx={1}>
            <Button
              variant='contained'
              type='error'
              onClick={() =>
                deleteRole(role).then((r) => !r.error && onClose())
              }
            >
              {t`delete`}
            </Button>
          </Box>
          <Box mx={1}>
            <Button variant='outlined' onClick={onClose}>
              {t`Cancel`}
            </Button>
          </Box>
        </DialogActions>
      </AlertDialogModal>
    </>
  );
};

DeleteRoleDialog.propTypes = {
  open: PropTypes.bool.isRequired
};

DeleteRoleDialog.defaultProps = {
  open: true
};

export default DeleteRoleDialog;
