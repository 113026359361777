import axios from 'axios';
import msalInstance, { scopes } from '../utils/msal-instance';
import { API_ENDPOINT, API_MOCK } from './settings';
import { DateTime } from 'luxon';

const axiosInstance = axios.create({
  baseURL: API_ENDPOINT
});

axiosInstance.interceptors.request.use(
  async (config) => {
    let token;
    try {
      token = await msalInstance.acquireTokenSilent({ scopes });
    } catch (e) {
      token = await msalInstance.acquireTokenRedirect({ scopes });
    }
    config.headers = {
      Authorization: `${token.tokenType} ${token.accessToken}`
    };

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export { axiosInstance };

export const mockRoutes = [
  { method: 'GET', path: '/users', response: { data: [] } },
  { method: 'GET', path: '/roles', response: { data: [] } },
  { method: 'GET', path: '/offices', response: { data: [] } },
  { method: 'GET', path: '/forms-of-payment', response: { data: [] } },
  {
    method: 'GET',
    path: '/issue-offices',
    response: {
      data: [{ issueOfficeId: 1, description: 'PDLS408AA', active: true }]
    }
  }
];

export const mockResolver = ({ method = 'GET', url }) => {
  const route = mockRoutes.find(
    (r) => r.method.toUpperCase() === method && url.includes(r.path)
  );
  if (!route) throw new Error('Not found');
  return { data: route.response };
};

export const convertDate = (date) => {
  return DateTime.fromISO(date).toFormat('yyyy-MM-dd');
};

export const convertDatetime = (date) => {
  return DateTime.fromISO(date).toFormat('yyyy-MM-dd HH:mm:ss');
};

export const convertToBase64 = (data, dataType = 'image/jpeg') => {
  return `data:${dataType};base64,${Buffer.from(data, 'binary').toString(
    'base64'
  )}`;
};

export const b64toUrl = async (base64Data) => {
  const r = await fetch(base64Data);
  const blob = await r.blob();
  return URL.createObjectURL(blob);
};

export const openBase64Window = (data, target = '_blank') => {
  b64toUrl(data).then((url) => window.open(url, target));
};

export class AxiosError extends Error {
  constructor(message, status, data) {
    super(Boolean(data?.description) ? data?.description : message);
    this.status = status;
    this.data = data;
  }
}

export const axiosBaseQuery = ({ baseUrl } = { baseUrl: '' }) => {
  return async ({
    url = '',
    method,
    body: data,
    destructFn = (d) => d.data,
    ...rest
  }) => {
    try {
      if (API_MOCK) return { data: destructFn(mockResolver({ method, url })) };
      const result = await axiosInstance({
        url: baseUrl + url,
        method,
        data,
        ...rest
      });
      return { data: destructFn(result) };
    } catch ({ response, message }) {
      if (response.status === 401) {
        return (window.location.href = '/401');
      }

      throw new AxiosError(Boolean(response?.data?.description) ? response?.data?.description : message, response?.status, response?.data);
    }
  };
};

export const FORM_STATUSES = {
  REGISTERED: 'Registered',
  PENDING: 'Pending'
};

export const PERSONAL_DOCUMENT_TYPES = [
  {
    type: 'CC',
    description: 'Citizen Card'
  },
  {
    type: 'C1TRIP',
    description: 'Crew Visa Functions'
  },
  {
    type: 'B1PAX',
    description: 'Crew Visa DHC'
  },
  {
    type: 'PSST',
    description: 'Passport'
  }
];
