import { t } from 'ttag';
import React from 'react';
import {
  Box,
  DialogContent,
  Dialog,
  DialogTitle,
  Typography,
  DialogActions
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { Button } from 'sata-mui-react-components';

import { notificationEventsValidationSchema } from './helpers';
import NotificationEventsForm from './NotificationEventsForm';
import RTKQueryFeedback from '../../../../components/common/RTKQueryFeedback';
import { useEditNotificationEventsMutation } from '../../../../store/apis/ops';

const EditNotificationEvents = ({ notificationEvent, open, onClose }) => {

  const [editUser, { data, error, isLoading }] =
    useEditNotificationEventsMutation();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: notificationEvent,
    validationSchema: notificationEventsValidationSchema,
    onSubmit: (values) => {
      editUser({
        ...values
      }).then((r) => !r.error && onClose());
    }
  });

  return (
    <>
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
        <DialogTitle>
          <Typography variant='h2' component='div'>
            {t`Notification Events`}
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <NotificationEventsForm formik={formik} />
        </DialogContent>
        <DialogActions>
          <Box p={1} px={0.4}>
            <Button variant='outlined' onClick={onClose}>
              {t`Cancel`}
            </Button>
          </Box>
          <Box p={1} px={0.4}>
            <Button variant='contained' onClick={formik.handleSubmit}>
              {t`Save`}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
      <RTKQueryFeedback error={error} isLoading={isLoading} data={data} />
    </>
  );
};

EditNotificationEvents.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func
};

EditNotificationEvents.defaultProps = {
  open: true
};

export default EditNotificationEvents;
