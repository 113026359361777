import React from 'react';
import PropTypes from 'prop-types';
import noUser from './profilephoto.svg';
import { Avatar, Box, Grid, Typography } from '@material-ui/core';
import { useGetUserImageByUserIdQuery } from '../../store/apis/ops';

const EmployeeCard = ({ employee: { employeeId, name, email }, label }) => {
  const { data } = useGetUserImageByUserIdQuery(employeeId);

  return (
    <Grid spacing={2} container>
      <Grid xs={12} item>
        <Typography variant='h3'>{label}</Typography>
      </Grid>
      <Grid item>
        <Avatar
          alt={name}
          src={data ? data : noUser}
          style={{ height: 75, width: 75 }}
        />
      </Grid>
      <Grid item>
        <Box display={'flex'} alignItems={'center'} height={'100%'}>
          <div>
            <Typography variant='h2'>{name}</Typography>
            <Typography>{email}</Typography>
          </div>
        </Box>
      </Grid>
    </Grid>
  );
};

EmployeeCard.prototypes = {
  label: PropTypes.string,
  employee: PropTypes.shape({
    employeeId: PropTypes.string,
    email: PropTypes.string,
    name: PropTypes.string
  }).isRequired
};

export default EmployeeCard;
