import { t } from "ttag";
import React, { useEffect } from "react";
import { Box, makeStyles, Typography, Button } from "@material-ui/core";
import { useAzureLogout, useAzureAuth } from "sata-azure-auth";
import { Link as RouterLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";

import logo from "../../layouts/main-layout/sata-logo.png";

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: "center",
    backgroundColor: theme.palette.background.default,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "center",
    minHeight: "100%",
    padding: theme.spacing(3),
    paddingTop: theme.spacing(10)
  },
  caption: {
    justifyContent: "flex-start",
    marginTop: theme.spacing(1),
    display: "flex",
    width: "20rem"
  },
  image: {
    maxWidth: "100%",
    width: "35rem",
    maxHeight: "16.75rem",
    height: "auto"
  },
  logo: {
    width: "9.375rem"
  }
}));

const Logout = () => {

  const classes = useStyles();
  const { isAuthenticated, isInitialised } = useAzureAuth();
  const logout = useAzureLogout();

  useEffect(() => {
    if (isInitialised && isAuthenticated) {
      logout();
    }

  }, [isAuthenticated, isInitialised, logout]);

  return (
    <div className={classes.root}>
      {isAuthenticated
        ? <Typography align="center" variant="h2">{t`Clearing your session, please wait...`}</Typography>
        : <Typography align="center" variant="h2">{t`Thank you. See you soon!`}</Typography>
      }
      <Box
        mt={10}
        display="flex"
        justifyContent="center"
      >
        <img
          alt="Under development"
          className={classes.image}
          src="/static/images/undraw_close_tab_uk6g.svg"
        />

      </Box>
      <Box
        mt={2}
        display="flex"
        justifyContent="flex-end"
        width="24rem"
      >
        <img
          alt="SATA"
          src={logo}
          className={classes.logo}
        />
      </Box>

      {!isAuthenticated &&
      <Box mt={4} display="flex" justifyContent="center">
        <Button
          color="secondary"
          component={RouterLink}
          to="/"
          variant="outlined"
        >
          {t`Go to home page`} &nbsp; &nbsp;
          <FontAwesomeIcon size="lg" icon={faHome} />
        </Button>
      </Box>
      }


    </div>
  );
};

export default Logout;
