import {convertToBase64} from '../../../utils/helpers';

export const type = 'documents';

export const documentsEndpoints = (builder) => ({
  getDocument: builder.query({
    query: ({documentId}) => ({
      url: `/documents/${documentId}`,
      method: 'GET',
      responseType: 'arraybuffer',
      destructFn: (d) => d?.data ? convertToBase64(d.data, 'application/pdf') : null
    }),
    providesTags: (_, __, {documentId}) => [{type, id: documentId }]
  }),

  getDocumentPdf: builder.query({
    query: ({documentId}) => ({
      url: `/documents/${documentId}.pdf`,
      method: 'GET',
      responseType: 'arraybuffer',
      destructFn: (d) => d?.data ? convertToBase64(d.data, 'application/pdf') : null
    }),
    providesTags: (_, __, {documentId}) => [{type, id: documentId }]
  }),

  updateDocument: builder.mutation({
    query: ({form, document}) => ({
      url: `/documents/${document.documentId}`,
      method: 'PUT',
      body: {
        formHash: form.hash,
        type: document.type,
        base64: document.base64
      }
    }),
    invalidatesTags: ({documentId}) => [{type, id: documentId }]
  }),
});

export default documentsEndpoints;
