import { t } from 'ttag';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { Chip, makeStyles, TextField } from '@material-ui/core';

import RTKQueryFeedback from '../common/RTKQueryFeedback';
import { useGetNotificationEmailsQuery } from '../../store/apis/ops';

const NotificationEmailsSearch = ({ value, onChange }) => {
  const {
    data: { data = [] } = {},
    error,
    isLoading
  } = useGetNotificationEmailsQuery(1);
  const classes = useStyles();
  const [input, setInput] = useState('');

  const onDelete = (emailId) => () => {
    onChange(value.filter((v) => v.emailId !== emailId));
  };
  const handleInputChange = (e, value) => {
    setInput(value);
  };
  const options = data.filter(e => !value.find(v => v.emailId === e.emailId));

  return (
    <div className={classes.root}>
      <RTKQueryFeedback error={error} />
      <Autocomplete
        multiple
        noOptionsText={t`No options`}
        loadingText={t`Loading...`}
        loading={isLoading}
        disableClearable
        options={options}
        inputValue={input}
        renderTags={() => null}
        getOptionLabel={(opt) => `${opt.email}`}
        filterOptions={(opts, { inputValue = '' }) =>
          opts.filter((o) =>
            `${o.emailId} ${o.email}`
              .toLowerCase()
              .includes(inputValue.toLowerCase())
          )
        }
        onInputChange={handleInputChange}
        onChange={(_, emails) => onChange(emails)}
        getOptionSelected={(option, value) => value.emailId === option.emailId}
        renderInput={(params) => (
          <TextField
            {...params}
            variant='outlined'
            placeholder={t`Emails`}
          />
        )}
      />
      <div className={classes.valueContainer}>
        {value.map((v) => (
          <Chip
            key={v.emailId}
            label={v.email}
            onDelete={onDelete(v.emailId)}
          />
        ))}
      </div>
    </div>
  );
};

NotificationEmailsSearch.propTypes = {
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired
};

NotificationEmailsSearch.defaultProps = {
  value: []
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(3)
    }
  },
  valueContainer: {
    '& > :not(:last-child)': {
      marginRight: theme.spacing(1)
    },
    '& > *': {
      marginBottom: theme.spacing(1)
    }
  }
}));

export default NotificationEmailsSearch;
