export const type = 'pendingForms';

export const pendingFormsEndpoints = (builder) => ({
  getPendingForms: builder.query({
    query: (params) => ({
      url: '/forms',
      method: 'GET',
      params,
      destructFn: (d) => d?.data
    }),
    providesTags: (data) => {
      return data?.data
        .map(({hash}) => ({ type, id: hash }))
        .concat({ type, id: 'list' });
    }
  }),

  addPendingForm: builder.mutation({
    query: (body) => ({
      url: '/forms',
      method: 'POST',
      body: body
    }),
    invalidatesTags: [{ type, id: 'list' }]
  }),
});

export default pendingFormsEndpoints;
