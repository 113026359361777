import React, {
  Suspense
} from "react";
import {
  Switch,
  Route
} from "react-router-dom";
import { LoadingScreen } from "sata-mui-react-components";

const DefaultGuard = ({ children }) => (<div>{children}</div>);
const DefaultLayout = ({ children }) => (<div>{children}</div>);

/**
 * Creates a router switch with provided routes definitions
 *
 * @param {Object} routes
 * @returns {JSX.Element}
 */
export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route?.guard || DefaultGuard;
        const Layout = route?.layout || DefaultLayout;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard {...props} >
                <Layout {...props}>
                  {route?.routes
                    ? renderRoutes(route.routes)
                    : <Component {...props} />}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);
