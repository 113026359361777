import { t } from 'ttag';
import React from 'react';
import {
  Box,
  DialogContent,
  Dialog,
  DialogTitle,
  Typography,
  DialogActions
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { Button } from 'sata-mui-react-components';

import NotificationEmailsForm from './NotificationEmailsForm';
import { notificationEmailsValidationSchema } from './helpers';
import { useAddNotificationEmailsMutation } from '../../../../store/apis/ops';
import RTKQueryFeedback from '../../../../components/common/RTKQueryFeedback';

const AddNotificationEmailsFormsDialog = ({ open, onClose }) => {
  const [addNotificationEmails, { data, error, isLoading }] = useAddNotificationEmailsMutation();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {},
    validationSchema: notificationEmailsValidationSchema,
    onSubmit: (values) => {
      addNotificationEmails({
        email: values.email,
      }).then((r) => !r.error && onClose());
    }
  });

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>
          <Typography variant='h2' component='div'>
            {t`Create new form Type`}
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <NotificationEmailsForm formik={formik} />
        </DialogContent>
        <DialogActions>
          <Box p={1} px={0.4}>
            <Button variant='outlined' onClick={onClose}>
              {t`Cancel`}
            </Button>
          </Box>
          <Box p={1} px={0.4}>
            <Button variant='contained' onClick={() => formik.handleSubmit()}>
              {t`Save`}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
      <RTKQueryFeedback error={error} isLoading={isLoading} data={data} />
    </>
  );
};

AddNotificationEmailsFormsDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func
};

AddNotificationEmailsFormsDialog.defaultProps = {
  open: true
};

export default AddNotificationEmailsFormsDialog;
