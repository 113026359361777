import React, { useEffect } from 'react';
import { useAzureAuth } from 'sata-azure-auth';
import { LoadingScreen } from 'sata-mui-react-components';
import { Box } from '@material-ui/core';

const Auth = ({ children, history, match }) => {
  const { isInitialised, isAuthenticated } = useAzureAuth();

  useEffect(() => {
    if (isInitialised && !isAuthenticated) {
      history.push('/auth');
      window.sessionStorage.setItem('_redirect_', match.url);
    }
  }, [isAuthenticated, history, isInitialised, match]);

  return isAuthenticated ? (
    <div className='auth-guard'>{children}</div>
  ) : (
    <Box mt={3}>
      <LoadingScreen />
    </Box>
  );
};

export default Auth;
