import { t } from 'ttag';
import React, { useMemo, useState } from 'react';
import { Add } from '@material-ui/icons';
import { CardHeader, Typography } from '@material-ui/core';
import { Button, Page, SimpleCard } from 'sata-mui-react-components';
import EditPendingFormDialog from './pending-forms-forms/Edit/EditPendingFormDialog';
import AddPendingFormsDialog from './pending-forms-forms/Add/AddPendingFormsDialog';
import { PendingFormsList } from './index';
import { useGetCurrentUserQuery } from '../../store/apis/ops';
import ViewPendingFormDialog from './pending-forms-forms/View/ViewPendingFormDialog';

const PendingForms = () => {
  const [pendingFormsToOpen, setPendingFormsToOpen] = useState(false);
  const [pendingFormToAdd, setPendingFormToAdd] = useState(false);

  const { data, isLoading } = useGetCurrentUserQuery();

  const isTrainee = useMemo(() => {
    return pendingFormsToOpen && data?.employeeId === pendingFormsToOpen?.trainee?.employeeId;
  }, [data, pendingFormsToOpen]);

  if (isLoading || !data) {
    return null;
  }

  return (
    <Page>
      <SimpleCard>
        <CardHeader
          title={
            <Typography variant={'h2'}>{t`Unregistered Forms`}</Typography>
          }
          action={
            <Button
              onClick={() => setPendingFormToAdd(true)}
              startIcon={<Add />}
            >{t`ADD`}</Button>
          }
        />

        <PendingFormsList openHandler={setPendingFormsToOpen} />

        {pendingFormToAdd && (
          <AddPendingFormsDialog
            onClose={() => setPendingFormToAdd(false)}
            pendingForm={setPendingFormToAdd}
          />
        )}

        {pendingFormsToOpen &&
          (isTrainee ? (
            <ViewPendingFormDialog
              onClose={() => setPendingFormsToOpen(false)}
              form={pendingFormsToOpen}
            />
          ) : (
            <EditPendingFormDialog
              onClose={() => setPendingFormsToOpen(false)}
              form={pendingFormsToOpen}
            />
          ))}
      </SimpleCard>
    </Page>
  );
};

PendingForms.propTypes = {};

PendingForms.defaultProps = {};

export default PendingForms;
