import { t } from 'ttag';
import React from 'react';
import {
  Box,
  DialogActions,
  DialogContent,
  Typography
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { AlertDialogModal, Button } from 'sata-mui-react-components';

import { useDeleteNotificationEmailsMutation } from '../../../../store/apis/ops';
import RTKQueryFeedback from '../../../../components/common/RTKQueryFeedback';

const DeleteEmployeeDialog = ({ open, onClose, email }) => {
  const [deleteEmail, { data, error, isLoading, isSuccess }] = useDeleteNotificationEmailsMutation();

  const handleDelete = () => {
    deleteEmail(email).then((r) => !r.error && onClose());
  };

  return (
    <>
      <RTKQueryFeedback
        data={data}
        error={error}
        isLoading={isLoading}
        isSuccess={isSuccess}
        successMessage={t`This item was successfully deleted!`}
      />
      <AlertDialogModal
        variant='error'
        title={t`Delete Notification Email`}
        open={open}
        onClose={onClose}
      >
        <DialogContent dividers>
          <Typography>
            {t`Do you really want to delete this item?`}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Box mx={1}>
            <Button variant='contained' type='error' onClick={handleDelete}>
              {t`Delete`}
            </Button>
          </Box>
          <Box mx={1}>
            <Button variant='outlined' onClick={onClose}>
              {t`Cancel`}
            </Button>
          </Box>
        </DialogActions>
      </AlertDialogModal>
    </>
  );
};

DeleteEmployeeDialog.propTypes = {
  employee: PropTypes.shape({
    employeeId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }),
  open: PropTypes.bool,
  onClose: PropTypes.func
};

DeleteEmployeeDialog.defaultProps = {
  open: true
};

export default DeleteEmployeeDialog;
