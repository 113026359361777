import React from 'react';
import { Redirect } from 'react-router-dom';
import { MainLayout } from './layouts';
import { Auth } from './components/guards';
import {
  Settings,
  RegisteredForms,
  ValidatedForms,
  PendingForms,
  ArchivedForms,
  UserDocumentsPending
} from './scenes';
import { Login, Logout } from './scenes/authentication';
import NotFound from './components/errors/NotFound';
import Unauthorized from './components/errors/Unauthorized';
import FormsToValidate from './scenes/forms-to-validate';

const routes = [
  {
    exact: true,
    path: '/401',
    component: Unauthorized
  },
  {
    exact: true,
    path: '/404',
    component: NotFound
  },
  {
    exact: true,
    path: '/auth',
    component: Login
  },
  {
    exact: true,
    path: '/logout',
    component: Logout
  },
  {
    path: '*',
    layout: MainLayout,
    guard: Auth,
    routes: [
      {
        exact: true,
        path: '/',
        component: () => <Redirect to={'/registered-forms'}/>
      },
      {
        exact: true,
        path: '/unregistered-forms',
        component: PendingForms
      },
      {
        exact: true,
        path: '/registered-forms',
        component: RegisteredForms
      },
      {
        exact: true,
        path: '/archived-forms',
        component: ArchivedForms
      },
      {
        exact: true,
        path: '/validated-forms',
        component: ValidatedForms
      },
      {
        exact: true,
        path: '/forms-to-validate',
        component: FormsToValidate
      },
      {
        exact: true,
        path: '/user-documents/pending',
        component: UserDocumentsPending
      },
      {
        exact: true,
        path: '/settings',
        component: () => <Redirect to={'/settings/form-types'} />
      },
      {
        path: '/settings',
        component: Settings
      },
      {
        component: () => <Redirect to='/404' />
      }
    ]
  }
];

export default routes;
