export const type = 'validatedForms';

export const validatedFormsEndpoints = (builder) => ({
  getValidatedForms: builder.query({
    query: (params) => ({
      url: `/validated-forms`,
      method: 'GET',
      params,
      destructFn: (d) => d?.data?.data
    }),
    providesTags: (data) => {
      return data?.data
        .map(({ hash }) => ({ type, id: hash }))
        .concat({ type, id: 'list' });
    }
  }),
});

export default validatedFormsEndpoints;
