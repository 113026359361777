import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { t } from 'ttag';
import styles from './attachments.module.scss';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useSnackbar } from 'notistack';
import IconButton from '@material-ui/core/IconButton';

const Attachments = ({
  attachments,
  onChange,
  fileSizeLimit,
  totalSizeLimit,
  allowedFileTypes,
  loadingHandlerFn,
  multiple,
  dropAttachmentsLabel,
  allowedFileTypesLabel,
  error,
  disabled,
  readOnly,
  dropZoneProps,
  onFilesRejected,
  onSizeLimitReached,
  onLoadFiles,
  hideResetBtn,
  hideFileElements,
  hideSizeFeedback,
  loading,
  children
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loadingFiles, setLoadingFiles] = useState(false);

  const localDataChangeHandler = (attachments) => {
    onChange([...attachments]);
  };

  const clearFiles = (e) => {
    e.preventDefault();
    e.stopPropagation();

    localDataChangeHandler([]);
  };

  const clearFile = (e, attachmentIdx) => {
    e.preventDefault();
    e.stopPropagation();

    attachments.splice(attachmentIdx, 1);
    localDataChangeHandler([...attachments]);
  };

  const localLoadingHandler = (loading) => {
    setLoadingFiles(loading);
    loadingHandlerFn(loading);
  };

  const onDropRejected = (rejectedFiles, event) => {
    const rejectedFilesWithReasons = rejectedFiles.map((f) => {
      let reason = 'Unknown error';
      const extension = f.file.name.split('.').pop();
      if (f.file.size > fileSizeLimit)
        reason = byteToMB(fileSizeLimit) + 'MB ' + t`exceeded`;
      if (!allowedFileTypes.includes(extension))
        reason = t`.${extension} not allowed`;
      return { name: f.file.name, size: f.file.size, reason };
    });
    onFilesRejected(rejectedFilesWithReasons)
  };

  const validateDroppedFiles = (files) => {
    const totalAttachmentsSize = attachments.reduce(
      (accumulator, currentFile) => accumulator + currentFile.size,
      0
    );
    const totalFilesAndAttachmentsSize = files.reduce(
      (accumulator, currentFile) => accumulator + currentFile.size,
      totalAttachmentsSize
    );
    const isTotalFilesAndAttachmentsSizeAllowed =
      totalFilesAndAttachmentsSize <= totalSizeLimit;

    let allowedDroppedFiles = files;
    let rejectedDroppedFiles = [];

    if (!isTotalFilesAndAttachmentsSizeAllowed) {
      allowedDroppedFiles = [];
      let totalSize = totalAttachmentsSize;
      const sortedFiles = files.sort((fa, fb) => fa.size - fb.size);
      sortedFiles.forEach((file) => {
        totalSize += file.size;
        if (totalSize <= totalSizeLimit) {
          allowedDroppedFiles.push(file);
        } else {
          rejectedDroppedFiles.push(file);
        }
      });

      const availableSize =
        totalSizeLimit -
        allowedDroppedFiles.reduce((a, f) => a + f.size, totalAttachmentsSize);
      if (rejectedDroppedFiles.length) {
        const availableSizeInMB = byteToMB(availableSize);
        console.warn(
          `Total size limit was reached. (only ${availableSizeInMB}MB available)`
        );
        onSizeLimitReached(availableSizeInMB)
      }
    }

    return { allowedDroppedFiles, rejectedDroppedFiles };
  };

  const onDrop = (files) => {
    if (!files || !Array.isArray(files)) {
      return false;
    }

    const { allowedDroppedFiles } = validateDroppedFiles(files);

    localLoadingHandler(true);
    Promise.all(allowedDroppedFiles.map((f) => loadFile(f)))
      .then((loadedFiles) => {
        localDataChangeHandler([...attachments, ...loadedFiles]);
        onLoadFiles(loadedFiles)
      })
      .catch((e) => {
        enqueueSnackbar(
          e.target?.error?.message ||
            e.message ||
            t`There was a problem loading your files`,
          { variant: 'error' }
        );
      })
      .finally(() => {
        localLoadingHandler(false);
      });
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    onDropRejected,
    maxSize: fileSizeLimit,
    accept: allowedFileTypes,
    multiple,
    disabled,
    noClick: readOnly,
    noDrag: readOnly,
    ...dropZoneProps
  });

  const fileNamesJsx = !hideFileElements && attachments.map((attach, idx) => (
    <span key={idx}>
      <IconButton size={'small'} onClick={(e) => clearFile(e, idx, attach)}>
        <DeleteIcon />
      </IconButton>
      <b>{attach.name}</b> ({byteToMB(attach.size)}MB)
      <br />
    </span>
  ));

  const resetBtn = !hideResetBtn && attachments.length ? (
    <Button
      //className={styles.deleteBtn}
      variant='contained'
      color='default'
      onClick={clearFiles}
      size={'small'}
      startIcon={<DeleteIcon />}
    >
      {t`Reset Files`}
    </Button>
  ) : null;

  const totalFileSizeLoaded = attachments.reduce(
    (accumulator, attach) => accumulator + attach.size,
    0
  );

  const sizeFeedback = !hideSizeFeedback && `(${byteToMB(totalFileSizeLoaded)}MB/${byteToMB(fileSizeLimit)}MB used)`;

  const containerClasses = [styles.dropzone];
  if (error) containerClasses.push(styles.error);
  if (disabled) containerClasses.push(styles.disabled);

  return (
    <>
      <div {...getRootProps({ className: containerClasses.join(' ') })}>
        {children}
        {resetBtn}
        <input {...getInputProps()} />
        {loading || loadingFiles ? (
          <CircularProgress color='inherit' />
        ) : (
          <p style={{ margin: '10px 3px' }}>
            {dropAttachmentsLabel} {sizeFeedback}
          </p>
        )}
        <small>
          <i>{`${allowedFileTypesLabel} ${allowedFileTypes}`}</i>
        </small>
      </div>
      {fileNamesJsx}
    </>
  );
};

export const byteToMB = (bytes) => (bytes / 1000000).toFixed(1);
export const loadFile = async (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = () =>
      resolve({
        content: btoa(reader.result),
        name: file.name,
        type: file.type,
        size: file.size
      });
    reader.onerror = (error) => reject(error);
  });
};

Attachments.propTypes = {
  dropAttachmentsLabel: PropTypes.string,
  allowedFileTypesLabel: PropTypes.string,
  attachments: PropTypes.array,
  allowedFileTypes: PropTypes.string,
  fileSizeLimit: PropTypes.number,
  totalSizeLimit: PropTypes.number,
  onChange: PropTypes.func,
  loadingHandlerFn: PropTypes.func,
  error: PropTypes.bool,
  multiple: PropTypes.bool,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  dropZoneProps: PropTypes.object,
  onFilesRejected: PropTypes.func,
  onSizeLimitReached: PropTypes.func,
  onLoadFiles: PropTypes.func,
  hideResetBtn: PropTypes.bool,
  hideFileElements: PropTypes.bool,
  hideSizeFeedback: PropTypes.bool,
  loading: PropTypes.bool,
};

Attachments.defaultProps = {
  dropAttachmentsLabel: 'Drop Attachments',
  allowedFileTypesLabel: 'Allowed types:',
  attachments: [],
  allowedFileTypes: '.jpg, .jpeg, .png, .doc, .docx, .pdf, .txt',
  fileSizeLimit: 10000000,
  totalSizeLimit: 10000000,
  onChange: () => null,
  loadingHandlerFn: () => null,
  error: false,
  multiple: true,
  readOnly: false,
  disabled: false,
  dropZoneProps: {},
  onFilesRejected: () => null,
  onSizeLimitReached: () => null,
  onLoadFiles: () => null,
  hideResetBtn: false,
  hideFileElements: false,
  hideSizeFeedback: false,
  loading: false,
};

export default Attachments;
