import {t} from 'ttag';
import React from 'react';
import {Edit} from '@material-ui/icons';
import {Box, Chip, IconButton} from '@material-ui/core';

export const columns = ({ editHandler }) => [
  {
    field: 'name',
    headerName: t`Name`,
    sortable: false,
    disableColumnMenu: true,
    flex: 1
  },
  {
    field: 'emails',
    headerName: t`Emails`,
    sortable: false,
    disableColumnMenu: true,
    flex: 3,
    renderCell: ({ row }) => {
      return (
        <>
          {row?.emails.map((e) => (
            <Box px={1}>
              <Chip label={e?.email} size='small' color='primary' />
            </Box>
          ))}
        </>
      );
    }
  },
  {
    field: 'action',
    headerName: t`Action`,
    type: 'number',
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    renderCell: ({ row }) => {
      return (
        <Box>
          <IconButton
            aria-label='delete'
            color='primary'
            onClick={() => editHandler(row)}
            size='small'
          >
            <Edit fontSize='inherit' />
          </IconButton>
        </Box>
      );
    }
  }
];

export const helper = {
  columns
};

export default helper;
