import React from 'react';
import {Page} from 'sata-mui-react-components';
import {useHistory, useRouteMatch} from 'react-router';
import {Box, Tab, Tabs} from '@material-ui/core';
import {t} from 'ttag';
import {Route, Switch} from 'react-router-dom';
import FormTypes from './form-types';
import NotificationEmails from './notification-emails';
import NotificationEvents from './notification-events';
import Employees from './employees';
import Roles from './roles';

const Settings = () => {

  const {path, url} = useRouteMatch();
  const history = useHistory();

  const a11yProps = (idx) => ({
    id: `simple-tab-${idx}`,
    'aria-controls': `simple-tabpanel-${idx}`
  });

  return (
    <Page>
      <Box sx={{width: '100%'}}>
        <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
          <Tabs
            value={history.location.pathname}
            onChange={(_, tab) => history.push(tab)}
            aria-label='Settings menu'
            variant={'scrollable'}
            scrollButtons={'auto'}
          >
            <Tab label={t`Form Types`} value={`${path}/form-types`} {...a11yProps(0)} />
            <Tab label={t`Employees`} value={`${path}/employees`} {...a11yProps(1)} />
            <Tab label={t`Roles`} value={`${path}/roles`} {...a11yProps(2)} />
            <Tab label={t`Notification Events`} value={`${path}/notification-events`} {...a11yProps(3)} />
            <Tab label={t`Notification Emails`} value={`${path}/notification-emails`} {...a11yProps(4)} />
          </Tabs>
        </Box>
        <Box>
          <Switch>
            <Route exact path={`${url}/form-types`}>
              <FormTypes/>
            </Route>
            <Route exact path={`${url}/employees`}>
              <Employees/>
            </Route>
            <Route exact path={`${url}/roles`}>
              <Roles/>
            </Route>
            <Route exact path={`${url}/notification-events`}>
              <NotificationEvents/>
            </Route>
            <Route exact path={`${url}/notification-emails`}>
              <NotificationEmails/>
            </Route>
          </Switch>
        </Box>
      </Box>
    </Page>
  );
};

export default Settings;
