import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import { CheckBox, Clear, AssignmentLate, Help } from '@material-ui/icons';

export const EVALUATIONS = {
  PASSED: { code: 'Passed', color: 'green', icon: <CheckBox /> },
  FAILED: { code: 'Failed', color: 'darkred', icon: <Clear /> },
  PARTIAL: { code: 'Partial', color: 'darkorange', icon: <AssignmentLate /> },
  UNKNOWN: { code: 'Unknown', color: 'gray', icon: <Help /> }
};

const EvaluationSpan = ({ evaluation }) => {

  let ev = EVALUATIONS.UNKNOWN;
  if (Object.keys(EVALUATIONS).includes(evaluation.toUpperCase())) {
    ev = EVALUATIONS[evaluation.toUpperCase()];
  }

  return (
    <Box sx={{ display: 'flex', color: ev.color }}>
      {ev.icon}
      <Typography variant={'button'}>{ev.code}</Typography>
    </Box>
  );
};

EvaluationSpan.prototypes = {
  evaluation: PropTypes.string
};

export default EvaluationSpan;
