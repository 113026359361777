import React, {useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {BasicTable} from 'sata-mui-react-components';

import {columns} from './employeesListHelper';
import {useGetUsersQuery} from '../../../store/apis/ops';
import RTKQueryFeedback from '../../../components/common/RTKQueryFeedback';
import {Grid, TextField} from "@material-ui/core";
import {t} from "ttag";

const EmployeesList = ({ removeHandler, editHandler }) => {
  const timeout = useRef();
  const [pattern, setPattern] = useState(null);
  const [page, setPage] = useState(1);
  const { data, error, isLoading } = useGetUsersQuery({
    page,
    pattern
  });

  const handlePatternInput = (e) => {
    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      setPattern(e.target.value)
    }, 1500);
  }

  return (
    <>
      <RTKQueryFeedback error={error} />
      <Grid item xs={12} md={6} lg={4}>
        <TextField
          fullWidth
          label={t`Search`}
          placeholder={'Search employee...'}
          variant='outlined'
          onChange={handlePatternInput}/>
      </Grid>
      <BasicTable
        pagination
        page={(page - 1)}
        rowCount={data?.count || 0}
        onPageChange={(newPage) => setPage(newPage + 1)}
        paginationMode="server"
        getRowId={(row) => row.userId}
        loading={isLoading}
        columns={columns({ editHandler, removeHandler })}
        rows={data?.data || []}
        autoHeight
        autoPageSize
        pageSize={data?.pagination?.rowsPerPage || 5}
        disableExtendRowFullWidth={true}
      />
    </>
  );
};

EmployeesList.propTypes = {
  setOpenEdit: PropTypes.func,
  setOpenRemove: PropTypes.func
};

EmployeesList.defaultProps = {};

export default EmployeesList;
