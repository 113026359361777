import {Box, IconButton} from '@material-ui/core';
import {Delete, Edit, OpenInNew} from '@material-ui/icons';
import {t} from 'ttag';
import React from 'react';
import UserPicture from '../../components/user-picture';

export const columns = ({ isMobile, openHandler, editHandler, removeHandler }) => [
  {
    field: 'user',
    headerName: t`User`,
    sortable: false,
    disableColumnMenu: true,
    flex: 3,
    renderCell: ({ row }) => {
      return (<UserPicture employeeId={row.user.employeeId} name={row.user.name}/>);
    }
  },
  {
    field: 'type',
    headerName: t`Type`,
    sortable: false,
    disableColumnMenu: true,
    hide: isMobile,
    flex: 3
  },
  {
    field: 'date',
    headerName: t`Issue Date`,
    sortable: false,
    disableColumnMenu: true,
    flex: 3
  },
  {
    field: 'actions',
    headerName: 'Actions',
    type: 'number',
    sortable: false,
    disableColumnMenu: true,
    hide: isMobile,
    flex: 1,
    renderCell: ({ row }) => {
      return (
        <Box>
          <IconButton
            aria-label='view'
            color='primary'
            onClick={() => openHandler(row.documentId)}
            size='small'
          >
            <OpenInNew fontSize='inherit' />
          </IconButton>
          <IconButton
            aria-label='edit'
            color='primary'
            onClick={() => editHandler(row.documentId)}
            size='small'
          >
            <Edit fontSize='inherit' />
          </IconButton>
          <IconButton
            aria-label='delete'
            color='primary'
            onClick={() => removeHandler(row)}
            size='small'
          >
            <Delete fontSize='inherit' />
          </IconButton>
        </Box>
      );
    }
  }
];

export const helper = {
  columns
};

export default helper;
