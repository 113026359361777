import React, {useState} from 'react';
import {CardHeader, Typography} from '@material-ui/core';
import {SimpleCard} from 'sata-mui-react-components';
import NotificationEventsList from './NotificationEventsList';
import {EditNotificationEvents} from './index';
import {t} from "ttag";

const NotificationEvents = () => {
  const [notificationEventsToEdit, setNotificationEventsToEdit] = useState(false);

  return (
    <>
      <SimpleCard>
        <CardHeader
          title={<Typography variant={'h2'}>{t`Notification Events`}</Typography>}
        />

        <NotificationEventsList
          editHandler={setNotificationEventsToEdit}
        />

        {notificationEventsToEdit && (
          <EditNotificationEvents
            onClose={() => setNotificationEventsToEdit(false)}
            notificationEvent={notificationEventsToEdit}
          />
        )}

      </SimpleCard>
    </>
  );
};

NotificationEvents.propTypes = {};

NotificationEvents.defaultProps = {};

export default NotificationEvents;
