import React from 'react';
import PropTypes from 'prop-types';
import './trainee-card.scss';
import {Box, Grid, Typography} from '@material-ui/core';
import {t} from 'ttag';
import {DateTime} from 'luxon';

const DocumentPersonCardInfo = ({ document }) => {
  if (!document) {
    return null;
  }
  const { number, country, date, validity } = document;
  const grid = !country ? 4 : 3;

  return (
    <Grid container spacing={2} style={{ display: 'flex', height:90}}>
      {number && (
        <Grid item xs={grid} style={{ display: 'table'}}>
          <Box style={{ display: 'table-cell', verticalAlign: 'middle' }}>
            <Typography variant='h3'>{t`Number`}</Typography>
            <Typography style={{ verticalAlign: 'middle' }}>
              {number}
            </Typography>
          </Box>
        </Grid>
      )}
      {country && (
        <Grid item xs={grid} style={{ display: 'table' }}>
          <Box style={{ display: 'table-cell', verticalAlign: 'middle' }}>
            <Typography variant='h3'>{t`Country`}</Typography>
            <Typography style={{ verticalAlign: 'middle' }}>
              {country}
            </Typography>
          </Box>
        </Grid>
      )}
      {date && (
        <Grid item xs={grid} style={{ display: 'table'  }}>
          <Box style={{ display: 'table-cell', verticalAlign: 'middle' }}>
            <Typography variant='h3'>{t`Issued date`}</Typography>
            <Typography style={{ verticalAlign: 'middle' }}>{DateTime.fromISO(date).toFormat('MMMM dd, yyyy')}</Typography>
          </Box>
        </Grid>
      )}
      {validity && (
        <Grid item xs={grid} style={{ display: 'table' }}>
          <Box style={{ display: 'table-cell', verticalAlign: 'middle' }}>
            <Typography variant='h3'>{t`Expiry date`}</Typography>
            <Typography style={{ verticalAlign: 'middle' }}>
              {DateTime.fromISO(validity).toFormat('MMMM dd, yyyy')}
            </Typography>
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

DocumentPersonCardInfo.prototypes = {
  document: PropTypes.object
};

export default DocumentPersonCardInfo;
