import React from 'react';
import PropTypes from 'prop-types';
import noUser from './profilephoto.svg';
import './trainee-card.scss';
import {Avatar, Box, Grid, Typography} from '@material-ui/core';
import {useGetUserImageByUserIdQuery} from '../../store/apis/ops';

const DocumentPersonCard = ({ user }) => {
  const { data } = useGetUserImageByUserIdQuery(user.employeeId);

  const { name, email } = user;

  return (
    <Grid container spacing={2} style={{ display: 'flex' }}>
      <Grid item>
        <Avatar
          alt={name}
          src={data ? data : noUser}
          style={{ height: 75, width: 75 }}
        />
      </Grid>
      <Grid item style={{ display: 'table' }}>
        <Box style={{ display: 'table-cell', verticalAlign: 'middle' }}>
          <Typography variant='h2' style={{}}>
            {name}
          </Typography>
          <Typography style={{ verticalAlign: 'middle' }}>{email}</Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

DocumentPersonCard.prototypes = {
  user: PropTypes.object
};

export default DocumentPersonCard;
