import { createMsalInstance } from 'sata-azure-auth';

import {MSA_AUTHORITY, MSA_CLIENT_ID, MSA_SCOPES} from './settings';

const settings = {
  auth: {
    clientId: MSA_CLIENT_ID,
    authority: MSA_AUTHORITY
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
  }
};

export const scopes = MSA_SCOPES;

const msalInstance = createMsalInstance(settings);

export default msalInstance;
