export const type = 'notificationEmails';
export const endpoints = (builder) => ({
  getNotificationEmails: builder.query({
    query: () => ({
      url: `/emails`,
      method: 'GET'
    }),
    providesTags: ({data} ) => {
      return data
        .map(({ emailId }) => ({ type, id: emailId }))
        .concat({ type, id: 'list' });
    }
  }),

  addNotificationEmails: builder.mutation({
    query: (body) => ({
      url: `/emails`,
      method: 'POST',
      body
    }),
    invalidatesTags: () => [{ type, id: 'list' }]
  }),

  editNotificationEmails: builder.mutation({
    query: (params) => ({
      url: `/emails`,
      method: 'PATCH',
      body: params
    }),
    invalidatesTags: () => [{ type, id: 'list' }]
  }),

  deleteNotificationEmails: builder.mutation({
    query: ({emailId}) => ({
      url: `/emails/${emailId}`,
      method: 'DELETE',
    }),
    invalidatesTags: () => [{ type, id: 'list' }]
  })
});

export default endpoints;
