export const type = 'registeredForms';

export const registeredFormsEndpoints = (builder) => ({
  getRegisteredForms: builder.query({
    query: (params) => ({
      url: `/registered-forms`,
      method: 'GET',
      params,
      destructFn: (d) => d?.data?.data
    }),
    providesTags: (data) => {
      return data?.data
        ?.map(({ hash }) => ({ type, id: hash }))
        .concat({ type, id: 'list' });
    }
  })
});

export default registeredFormsEndpoints;
