import {convertToBase64} from "../../../utils/helpers";

export const type = 'user';
export const userManagementEndPoints = (builder) => ({
  getUserImageByUserId: builder.query({
    query: (employeeId) => ({
      url: `/users/${employeeId}/photo`,
      method: 'GET',
      responseType: 'arraybuffer',
      destructFn: (d) => d?.data ? convertToBase64(d.data) : null
    })
  }),

  getUsersByPattern: builder.mutation({
    query: (pattern) => ({
      url: `/users?pattern=${pattern}&rows=9999`,
      method: 'GET',
      destructFn: (d) => d?.data?.data
    }),
    providesTags: (data) => {
      return data?.data
        .map(({ employeeId }) => ({ type, id: employeeId }))
        .concat({ type, id: 'list' });
    }
  }),

  getUsers: builder.query({
    query: (params) => ({
      url: `/users-list`,
      method: 'GET',
      params,
      destructFn: d => d?.data?.data,
    }),
    providesTags: (data) => {
      return data?.data
        .map(({ employeeId }) => ({ type, id: employeeId }))
        .concat({ type, id: 'list' });
    }
  }),

  addUser: builder.mutation({
    query: (user) => ({
      url: '/users/import',
      method: 'POST',
      body: user
    }),
    invalidatesTags: () => [{ type, id: 'list' }]
  }),

  editUser: builder.mutation({
    query: (user) => ({
      url: `/users/${user.employeeId}`,
      method: 'PATCH',
      body: user
    }),
    invalidatesTags: ({ employeeId }) => [{ type, id: employeeId }]
  }),

  disableUser: builder.mutation({
    query: ({ employeeId }) => ({
      url: `/users/${employeeId}/disable`,
      method: 'POST'
    }),
    invalidatesTags: ({ employeeId }) => [{ type, id: employeeId }]
  }),

  enableUser: builder.mutation({
    query: ({ employeeId }) => ({
      url: `/users/${employeeId}/enable`,
      method: 'POST'
    }),
    invalidatesTags: ({ employeeId }) => [{ type, id: employeeId }]
  }),

  getUserPicture: builder.query({
    query: ({ employeeId }) => ({
      url: `/user/${employeeId}/picture.jpg`,
      method: 'GET'
    })
  }),

  getUser: builder.query({
    query: (employeeId) => ({
      url: `/user/${employeeId}`,
      method: 'GET',
      destructFn: d => d?.data?.data
    }),
    providesTags: ({ employeeId }) => [{ type, id: employeeId }]
  }),

  deleteUser: builder.mutation({
    query: ({ employeeId }) => ({
      url: `/user/${employeeId}`,
      method: 'DELETE'
    }),
    invalidatesTags: ({employeeId}) => [{ type, id: employeeId }, { type, id: 'list' }]
  }),

  getCurrentUser: builder.query({
    query: (body) => ({
      url: `/user/me`,
      method: 'GET',
      body,
    }),
    transformResponse: (d) => d?.data,
  }),

  getAzureUsers: builder.mutation({
    query: (pattern) => ({
      url: `/users/search?pattern=${pattern}`,
      method: 'GET',
      destructFn: (d) => d?.data?.data
    })
  })
});

export default userManagementEndPoints;
