import { t } from 'ttag';
import React, { useState } from 'react';
import { UserMenu as Menu } from 'sata-mui-react-components';
import { useAzureAuth } from 'sata-azure-auth';
import { MenuItem } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import PinFormDialog from '../../scenes/pin-forms/PinFormDialog';
import {hasSomeOf, ROLES} from '../../utils/user/user-utils';
import {useGetCurrentUserQuery} from '../../store/apis/ops';

const UserMenu = () => {
  const { data: currentUser} = useGetCurrentUserQuery();
  const { account } = useAzureAuth();
  const history = useHistory();
  const [pinForm, setPinForm] = useState(false);
  if(!currentUser){
    return null;
  }

  return (
    <>
      <Menu name={account.name} role={account.username}>
        {hasSomeOf(currentUser, [ROLES.PNT]) && <MenuItem onClick={() => setPinForm(true)}>{t`Add Pin`}</MenuItem>}
        <MenuItem onClick={() => history.push('/logout')}>{t`Logout`}</MenuItem>
      </Menu>
      {pinForm && <PinFormDialog onClose={() => setPinForm(false)}/>}
    </>
  );
};

export default UserMenu;
