import { t } from 'ttag';
import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { Grid, TextField } from '@material-ui/core';
import TrainingTypeSelector from '../../../../components/training-type-selector';

const FormTypesFormsForm = ({ formik }) => {
  const [trainingType, setTrainingType] = useState(false);

  return (
    <Grid spacing={3} container>
      <Grid xs={12} md={6} item>
        <TextField
          label={t`Form Code`}
          name={'code'}
          color='primary'
          variant='outlined'
          onChange={(e) => formik.setFieldValue('code', e.target.value)}
          value={formik.values?.code || ''}
          error={Boolean(formik.errors.code)}
          helperText={formik.errors.code || ''}
          fullWidth
          required
        />
      </Grid>
      <Grid xs={12} md={6} item>
        <TextField
          label={t`Index`}
          name={'index'}
          color='primary'
          variant='outlined'
          onChange={(e) => formik.setFieldValue('index', e.target.value)}
          value={formik.values?.index || ''}
          error={Boolean(formik.errors.index)}
          helperText={formik.errors.index || ''}
          fullWidth
          required
        />
      </Grid>
      <Grid xs={12} item>
        <TextField
          label={t`Description`}
          name={'description'}
          color='primary'
          variant='outlined'
          onChange={(e) => formik.setFieldValue('description', e.target.value)}
          value={formik.values?.description || ''}
          error={Boolean(formik.errors.description)}
          helperText={formik.errors.description || ''}
          fullWidth
          required
          focused
        />
      </Grid>
      <Grid xs={12} item>
        <TrainingTypeSelector
          onChange={setTrainingType}
          trainingType={trainingType || null}
          label={t`Training Type`}
          fullWidth
        />
      </Grid>
      <Grid xs={12} item>
        <TextField
          name={'sharepointFolder'}
          color='primary'
          variant='outlined'
          placeholder={'Sharepoint Folder'}
          onChange={(e) => formik.setFieldValue('sharepointFolder', e.target.value)}
          value={formik.values?.sharepointFolder || ''}
          error={Boolean(formik.errors.sharepointFolder)}
          helperText={formik.errors.sharepointFolder || ''}
          fullWidth
        />
      </Grid>
    </Grid>
  );
};

FormTypesFormsForm.propTypes = {
  formik: PropTypes.object
};

FormTypesFormsForm.defaultProps = {
  formik: {}
};

export default FormTypesFormsForm;
