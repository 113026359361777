export const type = 'role';
export const userManagementEndPoints = (builder) => ({
  //  Get Roles list with patterns
  getRolesByPattern: builder.mutation({
    query: (pattern) => ({
      url: `/roles?pattern=${pattern}&rows=9999`,
      method: 'GET',
      destructFn: (d) => d?.data?.data
    }),
    providesTags: ({ data }) => {
      return data
        .map(({ roleId }) => ({ type, id: roleId }))
        .concat({ type, id: 'list' });
    }
  }),

  getAllRoles: builder.query({
    query: () => ({
      url: `/roles?rows=9999`,
      method: 'GET'
    }),
    providesTags: ({ data }) => {
      return data
        .map(({ roleId }) => ({ type, id: roleId }))
        .concat({ type, id: 'list' });
    }
  }),


  getRoles: builder.query({
    query: (page) => ({
      url: `/roles?page=${page}`,
      method: 'GET'
    }),
    providesTags: ({ data }) => {
      return data
        .map(({ roleId }) => ({ type, id: roleId }))
        .concat({ type, id: 'list' });
    }
  }),

  createRole: builder.mutation({
    query: (body) => ({
      url: `/roles`,
      method: 'POST',
      body
    }),
    invalidatesTags: [{ type, id: 'list' }]
  }),

  getRole: builder.query({
    query: (roleId) => ({
      url: `/role/${roleId}`,
      method: 'GET'
    }),
    providesTags: ({roleId}) => [{ type, id: roleId }]
  }),

  updateRole: builder.mutation({
    query: (body) => ({
      url: `/role/${body.roleId}`,
      method: 'PATCH',
      body
    }),
    invalidatesTags: ({roleId}) => [{ type, id: roleId }]
  }),

  deleteRole: builder.mutation({
    query: (body) => ({
      url: `/role/${body.roleId}`,
      method: 'DELETE',
      body
    }),
    invalidatesTags: ({roleId}) => [{ type, id: 'list' }, { type, id: roleId }]
  })
});

export default userManagementEndPoints;
