import {t} from 'ttag';
import React from 'react';
import UserPicture from '../../components/user-picture';
import {convertDate} from "../../utils/helpers";
import {Box, IconButton} from '@material-ui/core';
import {OpenInNew} from '@material-ui/icons';

export const columns = ({openHandler, isMobile }) => [
  {
    field: 'trainee',
    headerName: t`Trainee`,
    sortable: false,
    disableColumnMenu: true,
    flex: 3,
    renderCell: ({ row }) => {
      return (<UserPicture employeeId={row.trainee.employeeId} name={row.trainee.name}/>);
    }
  },
  {
    field: 'description',
    headerName: t`Description`,
    sortable: false,
    disableColumnMenu: true,
    hide: isMobile,
    flex: 3
  },
  {
    field: 'instructor',
    headerName: t`Instructor`,
    sortable: false,
    disableColumnMenu: true,
    flex: 3,
    renderCell: ({ row }) => {
      return (<UserPicture employeeId={row.instructor.employeeId} name={row.instructor.name}/>);
    }
  },
  {
    field: 'evaluation',
    headerName: t`Evaluation`,
    sortable: false,
    disableColumnMenu: true,
    hide: isMobile,
    flex: 3
  },
  {
    field: 'occurredOn',
    headerName: t`Occurred On`,
    sortable: false,
    disableColumnMenu: true,
    flex: 3,
    valueFormatter: ({ value }) => {
      return convertDate(value);
    }
  },
  {
    field: 'actions',
    headerName: 'Actions',
    type: 'number',
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    renderCell: ({ row }) => {
      return (
        <Box>
          <IconButton
            onClick={() => openHandler(row)}
            color={'primary'}
            size={'small'}
          >
            <OpenInNew fontSize={'inherit'} />
          </IconButton>
        </Box>
      );
    }
  }
];

export const helper = {
  columns
};

export default helper;
