import { t } from 'ttag';
import React, { useState } from 'react';
import {CardHeader, Typography} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { Button, SimpleCard } from 'sata-mui-react-components';

import FormTypesList from './FormTypesList';
import AddFormTypesFormsDialog from './form-types-forms/AddFormTypesFormsDialog';
import EditFormTypesFormsDialog from './form-types-forms';

const FormTypes = () => {
  const [formTypeToEdit, setFormTypeToEdit] = useState(false);
  const [formTypeToImport, setFormTypeToImport] = useState(false);

  return (
    <>
      <SimpleCard>
        <CardHeader
          title={<Typography variant={'h2'}>{t`Form Types`}</Typography>}
          action={
            <Button
              onClick={() => setFormTypeToImport(true)}
              startIcon={<Add />}
            >{t`ADD`}</Button>
          }
        />

        <FormTypesList editHandler={setFormTypeToEdit} />

        {formTypeToEdit && (
          <EditFormTypesFormsDialog
            onClose={() => setFormTypeToEdit(false)}
            form={formTypeToEdit}
          />
        )}

        {formTypeToImport && (
          <AddFormTypesFormsDialog onClose={() => setFormTypeToImport(false)} />
        )}

        {/*{formTypeToDelete && (*/}
        {/*  <DeleteFormTypeModal*/}
        {/*    onClose={() => setFormTypeToDelete(false)}*/}
        {/*    formTypes={formTypeToDelete}*/}
        {/*  />*/}
        {/*)}*/}
      </SimpleCard>
    </>
  );
};

FormTypes.propTypes = {};

FormTypes.defaultProps = {};

export default FormTypes;
