import React, {useEffect} from 'react';
import {Provider as ReduxProvider} from 'react-redux';
import {useActions} from '../../store/slices/app';
import store from '../../store/store';

const StoreProvider = ({children}) => {
  return (
    <ReduxProvider store={store}>
      <ReduxInit/>
      {children}
    </ReduxProvider>
  );
};

const ReduxInit = () => {
  const {onInit} = useActions();

  useEffect(() => {
    onInit();
  }, [onInit]);

  return null;
}

export default StoreProvider;
