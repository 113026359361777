import {t} from 'ttag';
import React from 'react';
import {Box, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from '@material-ui/core';
import PropTypes from 'prop-types';
import {useFormik} from 'formik';
import {Button} from 'sata-mui-react-components';
import EditUserDocumentsPendingForm from './EditUserDocumentsPendingForm';
import {useGetUserDocumentsByHashQuery, useUpdateUserDocumentMutation} from '../../../store/apis/ops';
import RTKQueryFeedback from '../../../components/common/RTKQueryFeedback';
import {editUserDocumentsPendingValidationSchema} from './helpers';

const EditUserDocumentsPendingDialog = ({hash, open, onClose}) => {
  const {data, error, isLoading} = useGetUserDocumentsByHashQuery({hash});
  const [updateUserDocument, {data: updateDocData, error: updateDocError, isLoading: updateDocLoading}] = useUpdateUserDocumentMutation();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: data,
    validationSchema: editUserDocumentsPendingValidationSchema,
    onSubmit: (values) => {
      updateUserDocument({
        ...values,
        documentId: hash
      }).then((r) => !r.error && onClose());
    }
  });

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>
          <Typography variant='h2' component='div'>
            {t`Edit Documents`}
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <EditUserDocumentsPendingForm formik={formik}/>
        </DialogContent>
        <DialogActions>
          <Box p={1} px={0.4}>
            <Button variant='outlined' onClick={onClose}>
              {t`Cancel`}
            </Button>
          </Box>
          <Box p={1} px={0.4}>
            <Button variant='contained' onClick={formik.handleSubmit}>
              {t`Save`}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
      <RTKQueryFeedback error={error} isLoading={isLoading}/>
      <RTKQueryFeedback data={updateDocData} error={updateDocError} isLoading={updateDocLoading}/>
    </>
  );
};

EditUserDocumentsPendingDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func
};

EditUserDocumentsPendingDialog.defaultProps = {
  open: true
};

export default EditUserDocumentsPendingDialog;
