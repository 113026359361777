import * as Yup from 'yup';
import { t } from 'ttag';
import { DateTime } from 'luxon';

export const pendingFormsValidationSchema = Yup.object().shape({
  instructor: Yup.object().required(t`Required field.`),
  trainee: Yup.object().required(t`Required field.`),
  type: Yup.object().required(t`Required field.`),
  occurredOn: Yup.string().required(t`Required field.`),
  group: Yup.string().required(t`Required field.`),
  date: Yup.string().required(t`Required field.`),
  validity: Yup.string().required(t`Required field.`)
});

export const editPendingFormsValidationSchema = Yup.object().shape({
  occurredOn: Yup.string()
    .required(t`Required field.`)
    .test({
      test: (value) => DateTime.fromISO(value).isValid,
      message: t`Should be a valid Date.`
    }),
  evaluation: Yup.string()
    .required(t`Required field.`)
    .nullable(),
  documents: Yup.array()
    .required()
    .test({
      test: (value, data) =>  value.some((doc) => doc.base64 && data.parent.type.code === doc.type),
      message: t`At least one file should be uploaded.`
    })
});
