import { t } from 'ttag';
import PropTypes from 'prop-types';
import {Grid, MenuItem, TextField} from '@material-ui/core';
import UserSearch from '../../../components/user-search';
import TrainingTypeSelector from '../../../components/training-type-selector';

const PendingFormsForm = ({ formik }) => {
  return (
    <Grid spacing={3} container>
      <Grid xs={12} md={6} item>
        <UserSearch
          user={formik.values?.instructor || null}
          error={Boolean(formik.errors.instructor)}
          helperText={formik.errors.instructor || ''}
          onSelectUser={(e) => formik.setFieldValue('instructor', e)}
          label={t`Instructor`}
        />
      </Grid>
      <Grid xs={12} md={6} item>
        <UserSearch
          user={formik.values?.trainee || null}
          textFiledError={Boolean(formik.errors.trainee)}
          textFiledHelperText={formik.errors.trainee || ''}
          onSelectUser={(e) => formik.setFieldValue('trainee', e)}
          label={t`Trainee`}
        />
      </Grid>
      <Grid xs={12} md={12} item>
        <TrainingTypeSelector
          label={t`Type`}
          trainingType={formik.values?.type || null}
          fullWidth
          onChange={(type) => formik.setFieldValue('type', type)}
          placeholder={t`Start typing code...`}
          textFiledError={Boolean(formik.errors.type)}
          textFiledHelperText={formik.errors.type || ''}
        />
      </Grid>
      <Grid xs={12} md={6} item>
        <TextField
          label={t`Occurred On`}
          name={'occurredOn'}
          type='date'
          color='primary'
          variant='outlined'
          onChange={(e) => formik.setFieldValue('occurredOn', e.target.value)}
          value={formik.values?.occurredOn || ''}
          error={Boolean(formik.errors.occurredOn)}
          helperText={formik.errors.occurredOn || ''}
          fullWidth
          required
          focused
        />
      </Grid>
      <Grid xs={12} md={6} item>
        <TextField
          label={t`Group`}
          name={'group'}
          color='primary'
          variant='outlined'
          onChange={(e) => formik.setFieldValue('group', e.target.value)}
          value={formik.values?.group || ''}
          error={Boolean(formik.errors.group)}
          helperText={formik.errors.group || ''}
          SelectProps={{
            MenuProps: {
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
              },
              getContentAnchorEl: null
            }
          }}
          fullWidth
          select
          required>
          <MenuItem key={'PNT'} value={'PNT'}>PNT</MenuItem>
          <MenuItem key={'PNC'} value={'PNC'}>PNC</MenuItem>
        </TextField>
      </Grid>
      <Grid xs={12} md={6} item>
        <TextField
          label={t`Issued Date`}
          name={'date'}
          type='date'
          color='primary'
          variant='outlined'
          onChange={(e) => formik.setFieldValue('date', e.target.value)}
          value={formik.values?.date || ''}
          error={Boolean(formik.errors.date)}
          helperText={formik.errors.date || ''}
          fullWidth
          required
          focused
        />
      </Grid>
      <Grid xs={12} md={6} item>
        <TextField
          label={t`Expiry Date`}
          name={'validity'}
          type='date'
          color='primary'
          variant='outlined'
          onChange={(e) => formik.setFieldValue('validity', e.target.value)}
          value={formik.values?.validity || ''}
          error={Boolean(formik.errors.validity)}
          helperText={formik.errors.validity || ''}
          fullWidth
          focused
          required
        />
      </Grid>
    </Grid>
  );
};

PendingFormsForm.propTypes = {
  formik: PropTypes.object
};

PendingFormsForm.defaultProps = {
  formik: {}
};

export default PendingFormsForm;
