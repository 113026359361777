import { t } from 'ttag';
import React from 'react';
import PropTypes from 'prop-types';
import { Grid, TextField } from '@material-ui/core';

const NotificationEmailsForm = ({ formik }) => {
  return (
    <Grid spacing={3} container>
      <Grid xs={12} item>
        <TextField
          label={t`Email`}
          name={'email'}
          color='primary'
          variant='outlined'
          onChange={(e) => formik.setFieldValue('email', e.target.value)}
          value={formik.values?.email || ''}
          error={Boolean(formik.errors.email)}
          helperText={formik.errors.email || ''}
          fullWidth
          required
        />
      </Grid>
    </Grid>
  );
};

NotificationEmailsForm.propTypes = {
  formik: PropTypes.object
};

NotificationEmailsForm.defaultProps = {
  formik: {}
};

export default NotificationEmailsForm;
