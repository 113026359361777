import { t } from "ttag";
import React, { useEffect } from "react";
import NProgress from "nprogress";
import { Box, LinearProgress, makeStyles, Typography } from "@material-ui/core";
import { useAzureLogin, useAzureAuth } from "sata-azure-auth";
import { useHistory } from "react-router-dom";

import logo from "../../layouts/main-layout/sata-logo.png";

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: "center",
    backgroundColor: theme.palette.background.default,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "center",
    minHeight: "100%",
    padding: theme.spacing(3),
    paddingTop: theme.spacing(10)
  },
  caption: {
    justifyContent: "flex-start",
    marginTop: theme.spacing(1),
    display: "flex",
    width: "20rem"
  },
  image: {
    maxWidth: "100%",
    width: "35rem",
    maxHeight: "16.75rem",
    height: "auto"
  },
  logo: {
    width: "9.375rem"
  }
}));


const Login = () => {

  const classes = useStyles();
  const { isAuthenticated, isInitialised } = useAzureAuth();
  const login = useAzureLogin();
  const history = useHistory();

  useEffect(() => {
    if (isInitialised && !isAuthenticated) {
      login();
    }

    if (isInitialised && isAuthenticated) {
      const redirectTo = window.sessionStorage.getItem("_redirect_");
      const path = redirectTo ? redirectTo : "/";
      history.push(path);
    }

  }, [isAuthenticated, isInitialised, login, history]);

  useEffect(() => {
    NProgress.start();

    return () => {
      NProgress.done();
    };
  }, []);

  return (
    <div className={classes.root}>
      <Typography align="center" variant="h2">{t`Login, please wait...`}</Typography>
      <Box width="20rem">
        <LinearProgress />
      </Box>
      <Typography component="p" className={classes.caption}
                  variant="caption">{t`Contacting Microsoft Azure...`}</Typography>
      <Box
        mt={10}
        display="flex"
        justifyContent="center"
      >
        <img
          alt="Under development"
          className={classes.image}
          src="/static/images/undraw_Secure_server_re_8wsq.svg"
        />

      </Box>
      <Box
        mt={2}
        display="flex"
        justifyContent="flex-end"
        width="24rem"
      >
        <img
          alt="SATA"
          src={logo}
          className={classes.logo}
        />
      </Box>

    </div>
  );
};

export default Login;
