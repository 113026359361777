import {Box, Chip, IconButton} from '@material-ui/core';
import {Edit} from '@material-ui/icons';
import {t} from 'ttag';
import React from 'react';

export const columns = ({ editHandler }) => [
  {
    field: 'code',
    headerName: t`Code`,
    sortable: false,
    disableColumnMenu: true,
    flex: 1
  },
  {
    field: 'description',
    headerName: t`Description`,
    sortable: false,
    disableColumnMenu: true,
    flex: 5
  },
  {
    field: 'docs',
    headerName: t`Uploads`,
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    renderCell: ({ row }) => {
      return (
        <>
          {row?.docs.map((e) => (
            <Box px={1}>
              <Chip label={e?.code} size='small' color='primary' />
            </Box>
          ))}
        </>
      );
    }
  },
  {
    field: 'actions',
    headerName: 'Actions',
    type: 'number',
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    renderCell: ({ row }) => {
      return (
        <Box>
          <IconButton
            aria-label='edit'
            color='primary'
            onClick={() => editHandler(row)}
            size='small'
          >
            <Edit fontSize='inherit' />
          </IconButton>
        </Box>
      );
    }
  }
];

export const helper = {
  columns
};

export default helper;
