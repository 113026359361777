export const type = 'formTypes';
export const endpoints = (builder) => ({
  getFormTypes: builder.query({
    query: (params) => ({
      url: `/form-types`,
      method: 'GET',
      params
    }),
    providesTags: ({ data }) => {
      return data
        .map(({ typeId }) => ({ type, id: typeId }))
        .concat({ type, id: 'list' });
    }
  }),
  addFormTypes: builder.mutation({
    query: (formType) => ({
      url: `/form-types`,
      method: 'POST',
      body: formType
    }),
    invalidatesTags: [{ type, id: 'list' }]
  }),
  editFormTypes: builder.mutation({
    query: (formType) => ({
      url: `/form-types/${formType.typeId}`,
      method: 'PUT',
      body: formType
    }),
    invalidatesTags: ({ typeId }) => [{ type, id: typeId }]
  }),
  deleteFormTypes: builder.mutation({
    query: ({typeId}) => ({
      url: `/form-types/${typeId}`,
      method: 'DELETE'
    }),
    invalidatesTags: [{ type, id: 'list' }]
  })
});

export default endpoints;
