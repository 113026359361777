import React from "react";
import {Box, Button, Container, makeStyles, Typography, useMediaQuery, useTheme} from "@material-ui/core";
import {Page} from "sata-mui-react-components";
import {t} from 'ttag';
import {useHistory} from 'react-router';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(3),
    paddingTop: 80,
    paddingBottom: 80
  },
  image: {
    maxWidth: "100%",
    width: 560,
    maxHeight: 300,
    height: "auto"
  }
}));

const NotFoundView = () => {
  const classes = useStyles();
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));
  const history = useHistory();

  return (
    <Page
      className={classes.root}
    >
      <Container maxWidth="lg">
        <Typography
          align="center"
          variant={mobileDevice ? "h4" : "h1"}
          color="textPrimary"
        >
          404: The page you are looking for isn’t here
        </Typography>
        <Typography
          align="center"
          variant="subtitle2"
          color="textSecondary"
        >
          You either tried some shady route or you
          came here by mistake. Whichever it is, try using the navigation.
        </Typography>
        <Box
          mt={6}
          display="flex"
          justifyContent="center"
        >
          <img
            alt="Under development"
            className={classes.image}
            src={`${process.env.PUBLIC_URL}/static/images/undraw_not_found_60pq.svg`}
          />
        </Box>
        <Box
          mt={6}
          display="flex"
          justifyContent="center"
        >
          <Button
            color="secondary"
            // component={RouterLink}
            // to="/"
            onClick={history.goBack}
            variant="outlined"
          >
            {t`Back`}
          </Button>
          &nbsp;
          <Button
            color="primary"
            // component={RouterLink}
            // to="/"
            onClick={() => history.push('/')}
            variant="outlined"
          >
            {t`Home`}
          </Button>
        </Box>
      </Container>
    </Page>
  );
};

export default NotFoundView;
