import {t} from 'ttag';
import React from 'react';
import PropTypes from 'prop-types';
import {Grid, TextField} from '@material-ui/core';
import Attachments from '../../../components/attachments';
import UserSearch from '../../../components/user-search';
import {Autocomplete} from '@material-ui/lab';
import {PERSONAL_DOCUMENT_TYPES} from '../../../utils/helpers';

const UserDocumentsPendingForm = ({ formik }) => {
  return (
    <Grid spacing={3} container>
      <Grid xs={12} item>
        <UserSearch
          user={formik.values?.user || null}
          onSelectUser={(e) => formik.setFieldValue('user', e)}
          label={t`Users`}
          textFiledError={Boolean(formik.errors.user)}
          textFiledHelperText={formik.errors.user}
        />
      </Grid>
      <Grid xs={12} item>
        <Autocomplete
          loadingText={t`Loading...`}
          getOptionSelected={(option, value) => value.type === option.type}
          renderInput={(params) => (
            <TextField
              {...params}
              variant='outlined'
              placeholder={t`Start typing document type...`}
              error={Boolean(formik.errors.type)}
              helperText={formik.errors.type || ''}
            />
          )}
          onChange={(e, type) => formik.setFieldValue('type', type)}
          options={PERSONAL_DOCUMENT_TYPES}
          getOptionLabel={(opt) => `${opt.type}`}
          renderOption={(item) => (
            <div className={'user-lookup-display'}>
              <strong>{item.type}</strong>
              <small>{item.description}</small>
            </div>
          )}
        />
      </Grid>
      <Grid xs={12} item>
        <TextField
          label={t`Number`}
          name={'number'}
          color='primary'
          variant='outlined'
          onChange={(e) => formik.setFieldValue('number', e.target.value)}
          value={formik.values?.number || ''}
          error={Boolean(formik.errors.number)}
          helperText={formik.errors.number || ''}
          fullWidth
          required
        />
      </Grid>
      <Grid xs={12} md={6} item>
        <TextField
          label={t`Issued Date`}
          name={'date'}
          type='date'
          color='primary'
          variant='outlined'
          onChange={(e) => formik.setFieldValue('date', e.target.value)}
          value={formik.values?.date || ''}
          error={Boolean(formik.errors.date)}
          helperText={formik.errors.date || ''}
          fullWidth
          required
          focused
        />
      </Grid>
      <Grid xs={12} md={6} item>
        <TextField
          label={t`Expiry Date`}
          name={'validity'}
          type='date'
          color='primary'
          variant='outlined'
          onChange={(e) => formik.setFieldValue('validity', e.target.value)}
          value={formik.values?.validity || ''}
          error={Boolean(formik.errors.validity)}
          helperText={formik.errors.validity || ''}
          fullWidth
          required
          focused
        />
      </Grid>
      <Grid xs={12} item>
        <Attachments
          onChange={([attachment]) => formik.setFieldValue('base64', `data:${attachment.type};base64,${attachment.content}`)}
          allowedFileTypes={'.pdf'}
          multiple={false}
          hideSizeFeedback
          hideResetBtn
        />
      </Grid>
    </Grid>
  );
};

UserDocumentsPendingForm.propTypes = {
  formik: PropTypes.object
};

UserDocumentsPendingForm.defaultProps = {
  formik: {}
};

export default UserDocumentsPendingForm;
