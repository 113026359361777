import { t } from 'ttag';
import React from 'react';
import {
  Box,
  DialogContent,
  Dialog,
  DialogTitle,
  Typography,
  DialogActions
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { Button } from 'sata-mui-react-components';

import FormTypesFormsForm from './FormTypesFormsForm';
import { formTypesValidationSchema } from './helpers';
import {useAddFormTypesMutation} from '../../../../store/apis/ops';
import RTKQueryFeedback from '../../../../components/common/RTKQueryFeedback';

const AddFormTypesFormsDialog = ({ open, onClose }) => {
  const [addFormType, { data, error, isLoading }] = useAddFormTypesMutation();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {},
    validationSchema: formTypesValidationSchema,
    onSubmit: (values) => {
      addFormType({
        code: values.code,
        index: values.index,
        description: values.description,
        sharepointFolder: values.sharepointFolder
      }).then((r) => !r.error && onClose());
    }
  });

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>
          <Typography variant='h2' component='div'>
            {t`Create new form Type`}
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <FormTypesFormsForm formik={formik} />
        </DialogContent>
        <DialogActions>
          <Box p={1} px={0.4}>
            <Button variant='outlined' onClick={onClose}>
              {t`Cancel`}
            </Button>
          </Box>
          <Box p={1} px={0.4}>
            <Button variant='contained' onClick={() => formik.handleSubmit()}>
              {t`Save`}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
      <RTKQueryFeedback error={error} isLoading={isLoading} data={data} />
    </>
  );
};

AddFormTypesFormsDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func
};

AddFormTypesFormsDialog.defaultProps = {
  open: true
};

export default AddFormTypesFormsDialog;
