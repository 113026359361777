import { t } from 'ttag';
import React from 'react';
import { Box, Grid, MenuItem, TextField, Typography } from '@material-ui/core';
import EmployeeCard from '../../../../components/employee-card';
import PropTypes from 'prop-types';
import { convertDate } from '../../../../utils/helpers';
import PendingDocumentView from '../PendingDocumentView';

const EditFormsForm = ({ formik }) => {
  return (
    <Grid spacing={3} container>
      <Grid xs={12} md={6} item>
        <EmployeeCard
          label={t`Instructor`}
          employee={formik.values?.instructor}
        />
      </Grid>
      <Grid xs={12} md={6} item>
        <EmployeeCard label={t`Trainee`} employee={formik.values?.trainee} />
      </Grid>
      <Grid xs={12} md={4} item>
        <Typography variant='h6'>{t`Description`}</Typography>
        <Typography>{formik.values?.description || '-'}</Typography>
      </Grid>
      <Grid xs={12} md={4} item>
        <TextField
          type='date'
          variant='outlined'
          name={'occurredOn'}
          label={t`Occurred On`}
          value={convertDate(formik.values?.occurredOn)}
          onChange={(e) => formik.setFieldValue('occurredOn', e.target.value)}
          error={Boolean(formik.errors.occurredOn)}
          helperText={formik.errors.occurredOn}
          fullWidth
          required
          focused
        />
      </Grid>
      <Grid xs={12} md={4} item>
        <TextField
          variant='outlined'
          name={'evaluation'}
          label={t`Evaluation`}
          value={formik.values?.evaluation || ''}
          onChange={(e) => formik.setFieldValue('evaluation', e.target.value)}
          error={Boolean(formik.errors.evaluation)}
          helperText={formik.errors.evaluation || ''}
          fullWidth
          required
          select
        >
          <MenuItem value={'Passed'}>{t`Passed`}</MenuItem>
          <MenuItem value={'Partial'}>{t`Partial`}</MenuItem>
          <MenuItem value={'Failed'}>{t`Failed`}</MenuItem>
        </TextField>
      </Grid>
      <Grid xs={12} item>
        <Box display={'flex'} justifyContent={'space-around'}>
          {formik.values?.documents.map((d, idx) => (
            <Box key={d.documentId} p={1}>
              <PendingDocumentView
                onChangeDocument={(base64) => {
                  formik.setFieldValue(
                    'documents',
                    formik.values?.documents.map((doc) => {
                      return doc.documentId === d.documentId
                        ? { ...doc, base64 }
                        : doc;
                    })
                  );
                }}
                error={Boolean(formik.errors?.documents)}
                document={d}
              />
            </Box>
          ))}
        </Box>
      </Grid>
    </Grid>
  );
};

EditFormsForm.propTypes = {
  formik: PropTypes.object
};

export default EditFormsForm;
