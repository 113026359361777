import React from 'react';
import {SidebarMenu} from 'sata-mui-react-components';
import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';
import {useGetCurrentUserQuery} from '../../store/apis/ops';
import {hasSomeOf, ROLES} from '../../utils/user/user-utils';
import {entriesAdmin, entriesPNCPNT} from './sidebarRoute';

const ApplicationMainNav = ({ isShortSidebar, currentPath }) => {
  const { data: currentUser, isLoading } = useGetCurrentUserQuery();
  const history = useHistory();

  if (isLoading || !currentUser) {
    return null;
  }

  return (
    <SidebarMenu
      entries={hasSomeOf(currentUser, [ROLES.PNC, ROLES.PNT]) ? entriesPNCPNT : entriesAdmin}
      shortSidebar={isShortSidebar}
      currentPath={currentPath}
      onClick={history.push}
    />
  );
};

ApplicationMainNav.propTypes = {
  isShortSidebar: PropTypes.bool.isRequired,
  currentPath: PropTypes.string.isRequired
};

ApplicationMainNav.defaultProps = {
  isShortSidebar: false,
  currentPath: '/'
};

export default ApplicationMainNav;
