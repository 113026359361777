export const type = 'trainingTypes';

export const trainingTypesEndpoints = (builder) => ({
  getAllTrainingTypes: builder.query({
    query: () => ({
      url: `/form-types?rows=9999`,
      method: 'GET',
      destructFn: (d) => d?.data?.data
    }),
    providesTags: (data =  []) => {
      return data
        .map(({ typeId }) => ({ type, id: typeId }))
        .concat({ type, id: 'list' });
    }
  }),
});

export default trainingTypesEndpoints;
