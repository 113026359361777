import {convertToBase64} from '../../../utils/helpers';

export const type = 'user_documents';

export const userDocumentsEndpoints = (builder) => ({
  getUserDocumentsPending: builder.query({
    query: (params) => ({
      url: `/user-documents/pending`,
      method: 'GET',
      params,
      destructFn: (d) => d?.data?.data
    }),
    providesTags: (data) => {
      return data?.data?.map(({ documentId }) => ({ type, id: documentId })).concat({ type, id: 'list' });
    }
  }),

  getUserDocumentsByHash: builder.query({
    query: ({hash}) => ({
      url: `/user-documents/${hash}`,
      method: 'GET',
      destructFn: (d) => d?.data?.data
    }),
    providesTags: ({documentId}) => [{ type, id: documentId }]
  }),

  getUserDocumentPdf: builder.query({
    query: ({documentId}) => ({
      url: `/user-documents/${documentId}.pdf`,
      method: 'GET',
      responseType: 'arraybuffer',
      destructFn: (d) => d?.data ? convertToBase64(d.data, 'application/pdf') : null
    }),
    providesTags: ({documentId}) => [{ type, id: documentId }]
  }),

  updateUserDocument: builder.mutation({
    query: (body) => ({
      url: `/user-documents/${body.documentId}`,
      method: 'PATCH',
      body
    }),
    invalidatesTags: ({documentId}) => [{ type, id: documentId }]
  }),

  deleteUserDocument: builder.mutation({
    query: ({documentId}) => ({
      url: `/user-documents/${documentId}`,
      method: 'DELETE',
    }),
    invalidatesTags: ({documentId}) => [{type, id: 'list' }, { type, id: documentId }]
  }),

  addUserDocuments: builder.mutation({
    query: (body) => ({
      url: '/user-documents',
      method: 'POST',
      body
    }),
    invalidatesTags: () => [{type, id: 'list' }]
  }),

  registerUserDocuments: builder.mutation({
    query: ({documentId}) => ({
      url: `/user-documents/${documentId}`,
      method: 'POST',
    }),
    invalidatesTags: ({documentId}) => [{type, id: 'list' }, { type, id: documentId }]
  }),
});



export default userDocumentsEndpoints;
