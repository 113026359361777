import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import Attachments from '../../../components/attachments';
import { openBase64Window } from '../../../utils/helpers';
import './PendingDocumentView.css';

const PendingDocumentView = ({
  onChangeDocument,
  document,
  error,
  disabled
}) => {
  const [attachment, setAttachment] = useState(null);
  return (
    <>
      <Attachments
        onChange={([attachment]) => {
          const base64 = `data:${attachment.type};base64,${attachment.content}`;
          setAttachment(base64);
          onChangeDocument(base64);
        }}
        dropAttachmentsLabel={document.type}
        allowedFileTypes={'.pdf'}
        multiple={false}
        error={error}
        disabled={disabled}
        hideSizeFeedback
        hideFileElements
        hideResetBtn
      >
        <Document file={attachment}>
          <Page
            pageNumber={1}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              openBase64Window(attachment);
            }}
          />
        </Document>
      </Attachments>
    </>
  );
};

export const DocumentShape = PropTypes.shape({
  documentId: PropTypes.string.isRequired,
  type: PropTypes.string,
  name: PropTypes.string,
  mediaType: PropTypes.string,
  metadata: PropTypes.shape({
    file: PropTypes.string,
    thumbnail: PropTypes.string
  })
});

PendingDocumentView.propTypes = {
  onChangeDocument: PropTypes.func,
  document: DocumentShape,
  error: PropTypes.bool,
  disabled: PropTypes.bool
};

PendingDocumentView.defaultProps = {
  onChangeDocument: () => null,
  document: null,
  error: false,
  disabled: false
};

export default PendingDocumentView;
