import React from 'react';
import PropTypes from 'prop-types';
import {Box, Grid} from '@material-ui/core';
import {DocumentPersonCard, DocumentPersonCardInfo} from '../../../components/document-person-card';
import {Document, Page} from "react-pdf/dist/esm/entry.webpack";
import {openBase64Window} from "../../../utils/helpers";
import {useGetUserDocumentPdfQuery} from "../../../store/apis/ops";
import Attachments from "../../../components/attachments";
import RTKQueryFeedback from "../../../components/common/RTKQueryFeedback";

const UserDocumentsPendingForm = ({document}) => {
  const {user} = document;
  const {data, error, isLoading} = useGetUserDocumentPdfQuery(document);

  return (
    <Grid spacing={3} container>
      <Grid xs={12} md={4} item>
        <DocumentPersonCard user={user}/>
      </Grid>
      <Grid xs={12} md={8} item>
        <DocumentPersonCardInfo document={document}/>
      </Grid>
      <Grid xs={12} item>
        <Box display={'flex'} justifyContent={'space-around'} overflow={'hidden'}>
          <Box p={1}>
            <Attachments
              readOnly
              hideResetBtn
              hideFileElements
              hideSizeFeedback
              dropAttachmentsLabel={''}
              allowedFileTypesLabel={''}
              allowedFileTypes={''}
            >
              <Document error={error} loading={isLoading && 'Loading...'} file={data}>
                <Page pageNumber={1} onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  openBase64Window(data)
                }}/>
              </Document>
            </Attachments>
          </Box>
        </Box>
      </Grid>
      <RTKQueryFeedback error={error} isLoading={isLoading}/>
    </Grid>
  );
};

UserDocumentsPendingForm.propTypes = {
  document: PropTypes.object
};

UserDocumentsPendingForm.defaultProps = {
  document: {}
};

export default UserDocumentsPendingForm;
