import {t} from 'ttag';
import {Archive, Contacts, HourglassEmpty, ListAlt, PictureAsPdf, Settings} from '@material-ui/icons';
import React from 'react';

export const entriesPNCPNT = {
  pendingForms: {
    title: t`Pending Forms`,
    icon: <HourglassEmpty />,
    path: '/unregistered-forms',
    exact: true
  },
  FormsToValidate: {
    title: t`Forms to validate`,
    icon: <ListAlt />,
    path: '/forms-to-validate',
    exact: false
  },
};

export const entriesAdmin = {
  pendingForms: {
    title: t`Unregistered Forms`,
    icon: <HourglassEmpty />,
    path: '/unregistered-forms',
    exact: true
  },
  registeredForms: {
    title: t`Registered Forms`,
    icon: <PictureAsPdf />,
    path: '/registered-forms',
    exact: true
  },
  validatedForms: {
    title: t`Validated forms`,
    icon: <ListAlt />,
    path: '/validated-forms',
    exact: false
  },
  archivedForms: {
    title: t`Archived Forms`,
    icon: <Archive />,
    path: '/archived-forms',
    exact: true
  },
  documents: {
    title: t`Documents`,
    icon: <Contacts />,
    path: '/user-documents/pending',
    exact: false
  },
  settings: {
    title: t`Settings`,
    icon: <Settings />,
    path: '/Settings',
    exact: false
  }
};
