import {Box, Chip, IconButton} from '@material-ui/core';
import {Delete} from '@material-ui/icons';
import {t} from 'ttag';
import React from 'react';

export const columns = ({ removeHandler }) => [
  {
    field: 'email',
    headerName: t`Email`,
    sortable: false,
    disableColumnMenu: true,
    flex: 1
  },
  {
    field: 'events',
    headerName: t`events`,
    sortable: false,
    disableColumnMenu: true,
    flex: 3,
    renderCell: ({ row }) => {
      return (
        <>
          {row?.events.map((e) => (
            <Box px={1}>
              <Chip label={e?.name} size='small' color='primary' />
            </Box>
          ))}
        </>
      );
    }
  },
  {
    field: 'actions',
    headerName: 'Actions',
    type: 'number',
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    renderCell: ({ row }) => {
      return (
        <Box>
          <IconButton
            aria-label='delete'
            onClick={() => removeHandler(row)}
            size='small'
          >
            <Delete fontSize='inherit' />
          </IconButton>
        </Box>
      );
    }
  }
];

export const helper = {
  columns
};

export default helper;
