import React from "react";
import "./user-picture.scss";
import PropTypes from "prop-types";
import noUser from "./profilephoto.svg";
import {Avatar, Grid, Typography} from '@material-ui/core';
import {useGetUserImageByUserIdQuery} from '../../store/apis/ops';

const UserPicture = ({employeeId, name}) => {
    const { data } = useGetUserImageByUserIdQuery(employeeId);

    return (
        <Grid container spacing={1} style={{display: 'flex'}} >
            <Grid item>
                <Avatar src={data ? data : noUser} style={{height: 24, width: 24}}/>
            </Grid>
            <Grid item  style={{display: 'table'}}>
                <Typography style={{display: 'table-cell', verticalAlign: 'middle'}}>{name}</Typography>
            </Grid>
        </Grid>
    )
};

UserPicture.prototypes = {
    employeeId: PropTypes.string.isRequired
};

export default UserPicture;
