import PropTypes from 'prop-types';
import React from 'react';
import {BasicTable} from 'sata-mui-react-components';

import {columns} from './notificationEmailsListHelper';
import {useGetNotificationEmailsQuery} from '../../../store/apis/ops';
import RTKQueryFeedback from '../../../components/common/RTKQueryFeedback';

const NotificationEmailsList = ({ removeHandler }) => {
  const { data, error, isLoading } = useGetNotificationEmailsQuery();

  return (
    <>
      <RTKQueryFeedback error={error} />
      <BasicTable
        // pagination
        // page={page - 1}
        // rowCount={data?.count || 0}
        // onPageChange={(newPage) => setPage(newPage + 1)}
        // paginationMode='server'
        getRowId={(row) => row.emailId}
        loading={isLoading}
        columns={columns({ removeHandler })}
        rows={data?.data || []}
        autoHeight
        // autoPageSize
        // pageSize={data?.pagination?.rowsPerPage || 5}
        disableExtendRowFullWidth={true}
      />
    </>
  );
};

NotificationEmailsList.propTypes = {
  setOpenEdit: PropTypes.func,
  setOpenRemove: PropTypes.func
};

NotificationEmailsList.defaultProps = {};

export default NotificationEmailsList;
