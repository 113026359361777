import React, {useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {BasicTable} from 'sata-mui-react-components';
import {columns} from './FormsToValidateListHelper';
import {useGetFormsToValidateQuery} from '../../store/apis/ops';
import RTKQueryFeedback from '../../components/common/RTKQueryFeedback';
import {Grid, TextField, useMediaQuery} from "@material-ui/core";
import {t} from "ttag";
import TrainingTypeSelector from "../../components/training-type-selector";


const FormsToValidateList = ({ openHandler }) => {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const timeout = useRef();
  const [page, setPage] = useState(1);
  const [pattern, setPattern] = useState(null);
  const [trainingType, setTrainingType] = useState(null);

  const { data = [], error, isLoading } = useGetFormsToValidateQuery({
    page,
    pattern,
  });

  const handlePatternInput = (e) => {
    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      setPattern(e.target.value)
    }, 1500);
  }

  return (
    <>
      <RTKQueryFeedback error={error} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={4}>
          <TextField
            fullWidth
            label={t`Search`}
            placeholder={'Search employee...'}
            variant='outlined'
            onChange={handlePatternInput}/>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <TrainingTypeSelector
            onChange={setTrainingType}
            trainingType={trainingType}
            label={t`Training Type`}
          />
        </Grid>
      </Grid>
      <BasicTable
        pagination
        page={(page - 1)}
        rowCount={data?.count || 0}
        onPageChange={(newPage) => setPage(newPage + 1)}
        paginationMode="server"
        getRowId={(row) => row.hash}
        loading={isLoading}
        columns={columns({ openHandler, isMobile })}
        onCellClick={({ row, field }) => {
          if (field !== 'actions') {
            openHandler(row);
          }
        }}
        rows={data}
        autoHeight
        autoPageSize
        pageSize={data?.pagination?.rowsPerPage || 5}
        disableExtendRowFullWidth={true}
      />
    </>
  );
};

FormsToValidateList.propTypes = {
  setOpenEdit: PropTypes.func,
  setOpenRemove: PropTypes.func
};

FormsToValidateList.defaultProps = {};

export default FormsToValidateList;
