import {t} from 'ttag';
import PropTypes from 'prop-types';
import {Button} from 'sata-mui-react-components';
import {Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography} from '@material-ui/core';
import RTKQueryFeedback from '../../../../components/common/RTKQueryFeedback';
import {useRegisterFormMutation} from '../../../../store/apis/ops';
import {editPendingFormsValidationSchema} from '../helpers';
import EditPendingFormsForm from './EditFormsForm';
import {useFormik} from 'formik';


const EditPendingFormDialog = ({
  form,
  open,
  onClose
}) => {
  const [registerForm, registerFormData] = useRegisterFormMutation();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...form
    },
    validationSchema: editPendingFormsValidationSchema,
    onSubmit: (values) => {
      registerForm({
        ...values
      }).then((r) => !r.error && onClose());
    }
  });

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth='lg'>
        <DialogTitle>
          <Typography variant='h2' component='div'>
            {t`${form?.trainee.name} Form: ${form?.type?.code}`}
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <EditPendingFormsForm formik={formik} />
        </DialogContent>
        <DialogActions>
          <Grid container style={{justifyContent: 'end'}}>
            <Grid item >
              <Box p={1} px={0.8}>
                <Button variant='outlined' onClick={onClose}>
                  {t`Cancel`}
                </Button>
              </Box>
            </Grid>
            <Grid item >
              <Box p={1} px={0.8}>
                <Button variant='contained' onClick={() => formik.handleSubmit()}>
                  {t`Save`}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      <RTKQueryFeedback error={registerFormData.error} isLoading={registerFormData.isLoading} data={registerFormData.data} />
    </>
  );
};

EditPendingFormDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func
};

EditPendingFormDialog.defaultProps = {
  open: true
};

export default EditPendingFormDialog;
