export const type = 'formsToValidate';

export const formsToValidateEndpoints = (builder) => ({
  getFormsToValidate: builder.query({
    query: (body) => ({
      url: `/forms-to-validate`,
      method: 'GET',
      body
    }),
    transformResponse: (d) => d?.data,
    providesTags: (data) => {
      return data
        .map(({ hash }) => ({ type, id: hash }))
        .concat({ type, id: 'list' });
    }
  }),
});

export default formsToValidateEndpoints;
