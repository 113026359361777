import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {createActionsHook} from 'react-redux-actions-hook';

export const slice = createSlice({
  name: 'app',
  initialState: {},
  reducers: {
    setAppState: (state, action) => ({...state, ...action.payload}),
  }
});

slice.actions.onInit = createAsyncThunk(
  'app/onInit',
  async (payload, {dispatch}) => {
    // dispatch(endpoints.getOffices.initiate());
    // dispatch(slice.actions.setAppState({appInitialized: true}));
  }
);

export const actions = slice.actions;

export const useActions = createActionsHook(slice.actions);

export default slice.reducer;
